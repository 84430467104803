import React, { useEffect, useState } from "react";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import * as qs from "query-string";
import { createEmailSignup, getCoaches } from "../../api/api";
import "./CoachList.scss";
import BottomNav from "../BottomNav/BottomNav";
import CoachListPanel from "../CoachListPanel/CoachListPanel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Pagination from "@material-ui/lab/Pagination";
import Signup from "../Signup/Signup.js";
import Login from "../Login/Login";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Colors from "../../constants/colors";
import GeocodingUtils from "../../utils/GeocodingUtils";

//mycode
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { sports } from "src/constants/sports";

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

function validateZip(zip) {
  const re = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return re.test(String(zip).toLowerCase());
}

const spinnerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#006400",
    },
  },
});

const textFieldColor = Colors.secondary;
const textFieldColorNormal = Colors.secondary;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "max-content",
    marginRight: "20px",
    flexDirection: "row",

    "& .MuiTextField-root": {
      marginRight: "20px",

      "& label.Mui-focused": {
        color: textFieldColor,
      },
      "& label": {},
      "& .MuiInput-underline:after": {
        borderBottomColor: textFieldColor,
      },
      "& .MuiOutlinedInput-root": {
        margin: "0rem",
        "& fieldset": {
          borderColor: textFieldColorNormal,
          borderRadius: "0",
          borderWidth: "1.5px",
        },
        "&:hover fieldset": {
          borderColor: textFieldColor,
          borderWidth: "1.5px",
        },
        "&.Mui-focused fieldset": {
          borderColor: textFieldColor,
          borderWidth: "1.5px",
        },
      },
    },
  },

  formControl: {},
  p: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    fontFamily:
      "Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
  },
  label: {},
}));

const CoachList = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  console.log(query);
  const history = useHistory();
  const [error, setError] = useState("");
  const [coaches, setCoaches] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page] = useState(query.page ? query.page : 0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [zip, setZip] = useState(
    qs.parse(props.location.search)["zip"]
      ? qs.parse(props.location.search)["zip"]
      : ""
  );
  const [sport, setSport] = useState(
    qs.parse(props.location.search)["sport"]
      ? qs.parse(props.location.search)["sport"]
      : ""
  );
  const [queryParams, setQueryParams] = useState(
    qs.parse(props.location.search)
  );
  const [radius, setRadius] = useState(
    queryParams["radius"] ? queryParams["radius"] : 30
  );
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [searchErrorSnackBar, setSearchErrorSnackBar] = useState(false);
  const [searchErrorSnackBarMessage, setSearchErrorSnackBarMessage] =
    useState("");
  const [emailSignup, setEmailSignup] = useState("");
  const [phoneSignup, setPhoneSignup] = useState("");
  const [firstNameSignup, setFirstNameSignup] = useState("");
  const [messageSignup, setMessageSignup] = useState("");
  const [zipSignup, setZipSignup] = useState(
    qs.parse(props.location.search)["zip"]
      ? qs.parse(props.location.search)["zip"]
      : ""
  );
  const [sportSignup, setSportSignup] = useState(
    qs.parse(props.location.search)["sport"]
      ? qs.parse(props.location.search)["sport"]
      : ""
  );
  const [successMessage, setSuccessMessage] = useState(null);
  const [showHeader, setShowHeader] = useState(true);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const zipParam = searchParams.get("zip");
  const sportParam = searchParams.get("sport");
  const radiusParam = searchParams.get("radius");
  const pageParam = searchParams.get("page");

  const styleClasses = useStyles();

  useState(function () {
    if (!props.location.search["zip"]) {
      GeocodingUtils.GetZip(
        function (res) {
          setZip(res);
        },
        function (err) {
          console.log("err", err);
        }
      );
    }
  });

  const openLoginHandler = () => {
    setShowLogin(true);
  };

  const closeLoginHandler = () => {
    setShowLogin(false);
  };

  const openSignupHandler = () => {
    setShowSignup(true);
  };

  const closeSignupHandler = () => {
    setShowSignup(false);
  };

  const handleEmailForm = (e) => {
    e.preventDefault();

    if (!emailSignup) {
      setError("Please enter an email.");
      setOpenSnackBar(true);
      return;
    }

    if (!firstNameSignup) {
      setError("Please enter first name.");
      setOpenSnackBar(true);
      return;
    }

    if (!messageSignup) {
      setError("Please enter a message.");
      setOpenSnackBar(true);
      return;
    }

    if (!zipSignup) {
      setError("Please enter a zip code.");
      setOpenSnackBar(true);
      return;
    }

    if (!sportSignup) {
      setError("Please enter a sport.");
      setOpenSnackBar(true);
      return;
    }

    createEmailSignup(
      emailSignup,
      sportSignup,
      zipSignup,
      firstNameSignup,
      messageSignup,
      phoneSignup
    )
      .then((response) => {
        setSuccessMessage("Thanks for signing up!");
        setEmailSignup("");
      })
      .catch((e) => console.log(e));
  };

  let loginDialog;
  if (showLogin) {
    loginDialog = (
      <Dialog
        onClose={closeLoginHandler}
        open={showLogin}
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogContent dividers>
          <Login
            switch={() => {
              closeLoginHandler();
              openSignupHandler();
            }}
          />
        </DialogContent>
      </Dialog>
    );
  } else {
    loginDialog = null;
  }

  let signupDialog;
  if (showSignup) {
    signupDialog = (
      <Dialog
        onClose={closeSignupHandler}
        open={showSignup}
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogContent dividers>
          <Signup
            switch={() => {
              closeSignupHandler();
              openLoginHandler();
            }}
            close={closeSignupHandler}
          />
        </DialogContent>
      </Dialog>
    );
  } else {
    signupDialog = null;
  }

  useEffect(() => {
    // Required so that the useEffect logic does not try to change state if the
    // component is unmounted. See https://juliangaramendy.dev/use-promise-subscription/
    let isSubscribed = true;
    setIsLoaded(false);
    if (!zip || !radius || !sport) {
      setIsLoaded(true);
      return;
    }
    getCoaches(
      zipParam,
      radiusParam,
      sportParam,
      pageParam,
      queryParams["limit"] ? queryParams["limit"] : 1000
    )
      .then((res) => {
        if (isSubscribed) {
          setCoaches(res.coaches);
          setPageCount(res.total_pages);
        }
        setIsLoaded(true);
      })
      .catch(function (error) {
        if (isSubscribed) {
          setError(JSON.stringify(error));

          setIsLoaded(true);
        }
        isSubscribed = false;
      });

    return () => (isSubscribed = false);
    // eslint-disable-next-line
  }, [zipParam, radiusParam, sportParam, pageParam]);

  const searchHandler = (e) => {
    e.preventDefault();
    setShowHeader(false);

    if (!zip || zip === "" || !validateZip(zip)) {
      setSearchErrorSnackBarMessage("Please enter a valid 5-digit zip code.");
      setSearchErrorSnackBar(true);
      return;
    }
    if (!sport || sport === "") {
      setSearchErrorSnackBarMessage("Please select a sport.");
      setSearchErrorSnackBar(true);
      return;
    }

    const query =
      "?zip=" + zip + "&sport=" + sport + "&radius=" + radius + "&page=" + page;
    setQueryParams(qs.parse(query));
    var newurl = window.location.pathname + query;
    console.log(newurl);
    history.push(newurl);
  };

  const showProfileHandler = (coach) => {
    // setShowCoachProfile(true);
    // setShowMessageCoach(false);
    // setShowBookSession(false);
    // setCoachToShow(coach);
    // console.log('here', coach);
    history.push(
      "/coach-profile/" + (coach.customUrl ? coach.customUrl : coach.id)
    );
  };

  const pageChangeHandler = (event, value) => {
    if (page === value - 1) {
      return;
    }
    const query =
      "?zip=" +
      zip +
      "&sport=" +
      sport +
      "&radius=" +
      radius +
      "&page=" +
      (value - 1);
    setQueryParams(qs.parse(query));
    var newurl = window.location.pathname + query;
    console.log(newurl);
    history.push(newurl);
  };

  let screenToDisplay = (
    <div>
      {showHeader && (
        <div className="InitialHeader">
          <h3>Enter your sport & zip code below to find coaches near you!</h3>
        </div>
      )}
      <div className="CoachListHeader">
        {/*{queryParams['sport'] ? <p>*/}
        {/*Your Coaches for {queryParams['sport'].slice(0, 1).toUpperCase() + queryParams['sport'].slice(1)}</p> : null}*/}
        <p>Available Coaches</p>
        <p>Zip Code: {queryParams["zip"]}</p>
      </div>
      <div className={"coachListMainWrapper"}>
        <div className="CoachListSearch">
          <form className={styleClasses.root}>
            {<TextField
              name={sport}
              variant="outlined"
              select
              label="Sport"
              value={sport}
              onChange={(e) => setSport(e.target.value)}
              SelectProps={{ style: { minWidth: '15ch', boxShadow: 'none' } }}
            >

              <MenuItem value={"basketball"}>Basketball</MenuItem>
              <MenuItem value={"soccer"}>Soccer</MenuItem>
              <MenuItem value={"field-hockey"}>Field Hockey</MenuItem>
              <MenuItem value={"lacrosse"}>Lacrosse</MenuItem>
              <MenuItem value={"baseball"}>Baseball</MenuItem>
              <MenuItem value={"softball"}>Softball</MenuItem>
              <MenuItem value={"football"}>Football</MenuItem>
              <MenuItem value={"track-and-field"}>Track &amp; Field</MenuItem>
              <MenuItem value={"strength-and-speed"}>Strength &amp; Speed</MenuItem>
              <MenuItem value={"mental-performance"}>Mental Performance</MenuItem>

            </TextField>}

            <TextField
              name={radius}
              variant="outlined"
              select
              label="Max. Distance"
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
              SelectProps={{ style: { minWidth: "15ch", boxShadow: "none" } }}
            >
              <MenuItem value={5}>5 miles</MenuItem>
              <MenuItem value={10}>10 miles</MenuItem>
              <MenuItem value={20}>20 miles</MenuItem>
              <MenuItem value={30}>30 miles</MenuItem>
              <MenuItem value={50}>50 miles</MenuItem>
            </TextField>
            <TextField
              name={zip}
              variant="outlined"
              label="Zip Code"
              value={zip}
              type="zipCode"
              onChange={(e) => setZip(e.target.value)}
              inputProps={{ style: { boxShadow: "none" } }}
            ></TextField>
            <button
              className="CoachListButtonSearch"
              onClick={(e) => searchHandler(e)}
            >
              Search
            </button>
          </form>
        </div>
        <Snackbar open={openSnackbar} onClose={(e) => setOpenSnackBar(false)}>
          <Alert onClose={(e) => setOpenSnackBar(false)} severity="error">
            {error}
          </Alert>
        </Snackbar>
        <Snackbar
          open={successMessage}
          onClose={(e) => setSuccessMessage(null)}
        >
          <Alert onClose={(e) => setSuccessMessage(null)}>
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={searchErrorSnackBar}
          onClose={(e) => setSearchErrorSnackBar(false)}
        >
          <Alert
            onClose={(e) => setSearchErrorSnackBar(false)}
            severity="error"
          >
            {searchErrorSnackBarMessage}
          </Alert>
        </Snackbar>
        <div className="greenBoxRankingList">
          {coaches.map((item, i) => (
            <CoachListPanel
              key={i}
              coach={item}
              sport={sportParam}
              onClickViewProfile={showProfileHandler}
              onClickMessageCoach={() => {
                history.push(
                  "/message-coach/" +
                  (item.customUrl ? item.customUrl : item.id)
                );
              }}
              onClickBookSession={() => {
                history.push(
                  "/book-session/" + (item.customUrl ? item.customUrl : item.id)
                );
              }}
            />
          ))}
          {coaches.length > 0 ? (
            <Pagination
              count={pageCount + 1}
              page={page + 1}
              onChange={pageChangeHandler}
            />
          ) : null}
        </div>
        {coaches && queryParams["zip"] && coaches.length === 0 ? (
          <div className="noResultsContainer">
            <div className="left">
              <h2>One More Step</h2>
              <p>
                Training with a private sports coach can be lifechanging for
                young athletes. In order to untap your child's potential and
                help you find the right coach, we would love to get a better
                sense of your expectations. Anything you can share - your
                child’s skill level, age, goals, etc. - will help our coach
                recommendation process.
              </p>
              <p>
                Once you submit your information on the right, someone from our
                team will follow up very shortly. In case you're unsure of what
                to say, here are some common messages we receive from parents:
              </p>
              <ul>
                <li>
                  My son (middle school) plays travel basketball and is looking
                  to improve his skills. He needs help with shooting in
                  particular. Do you have a coach in our area who can help?
                </li>
                <li>
                  My 16yo daughter wants to play college soccer. We know she's
                  talented, but she needs to be challenged by an experienced
                  coach if she wants to make the next level. Do you have someone
                  locally who can work with her?
                </li>
                <li>
                  Hi there! We're looking for a private baseball coach who can
                  teach the fundamentals. Our son is 8 years old and LOVES
                  baseball, but both of his teams are coached by volunteer dads.
                  Do you have a private coach nearby who can improve his
                  pitching and hitting?
                </li>
              </ul>
            </div>
            <div className="right">
              <h4>Tell us about your ideal coach!</h4>
              <form onSubmit={(e) => handleEmailForm(e)} className="emailForm">
                <TextField
                  className="emailInput"
                  value={emailSignup}
                  onChange={(e) => setEmailSignup(e.target.value)}
                  name="email"
                  type="email"
                  placeholder="Email*"
                  variant="outlined"
                />
                <TextField
                  value={phoneSignup}
                  onChange={(e) => setPhoneSignup(e.target.value)}
                  name="phone"
                  type="phone"
                  placeholder="Phone Number"
                  variant="outlined"
                />
                <TextField
                  value={firstNameSignup}
                  onChange={(e) => setFirstNameSignup(e.target.value)}
                  name="firstName"
                  type="text"
                  placeholder="Name*"
                  variant="outlined"
                />
                <TextField
                  value={zipSignup}
                  onChange={(e) => setZipSignup(e.target.value)}
                  name="zip"
                  type="text"
                  placeholder="Zip Code*"
                  variant="outlined"
                />

                {/* <TextField
                                    name={sportSignup}
                                    variant="outlined"
                                    select
                                    label="Sport"
                                    value={sportSignup}
                                    onChange={(e) => setSportSignup(e.target.value)}
                                    SelectProps={{ style: { minWidth: '15ch', boxShadow: 'none', textAlign: 'left' } }}
                                >

                                    <MenuItem value={"basketball"}>Basketballllll</MenuItem>
                                    <MenuItem value={"soccer"}>Soccer</MenuItem>
                                    <MenuItem value={"field-hockey"}>Field Hockey</MenuItem>
                                    <MenuItem value={"lacrosse"}>Lacrosse</MenuItem>
                                    <MenuItem value={"baseball"}>Baseball</MenuItem>
                                    <MenuItem value={"softball"}>Softball</MenuItem>
                                    <MenuItem value={"football"}>Football</MenuItem>
                                    <MenuItem value={"track-and-field"}>Track &amp; Field</MenuItem>
                                    <MenuItem value={"strength-and-speed"}>Strength &amp; Speed</MenuItem>
                                    <MenuItem value={"mental-performance"}>Mental Performance</MenuItem>

                                </TextField> */}

                <Autocomplete
                  sx={{ width: 280 }}
                  selectOnFocus
                  autoHighlight={true}
                  blurOnSelect={true}
                  options={sports}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) =>
                    setSportSignup(newValue ? newValue.value : "")
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <span
                        style={{
                          fontWeight: 100,
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        }}
                      >
                        {option.label}
                      </span>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={sportSignup}
                      name={sportSignup}
                      type="sport"
                      label="Sport"
                      variant="outlined"
                    />
                  )}
                />

                <TextField
                  multiline
                  value={messageSignup}
                  onChange={(e) => setMessageSignup(e.target.value)}
                  name="message"
                  type="text"
                  rows={4}
                  placeholder="Tell us about your ideal coach*"
                  variant="outlined"
                />
                <button style={{ background: Colors.secondary }} type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        ) : null}
        {loginDialog}
        {signupDialog}
        <BottomNav />
      </div>
    </div>
  );

  if (!isLoaded) {
    return (
      <div>
        <div
          className="loadingContainer"
          style={{
            marginTop: "5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ThemeProvider theme={spinnerTheme}>
            <CircularProgress />
            <div className="loadingText text-large text-bold">Loading</div>
          </ThemeProvider>
        </div>
        <div className="optimizing">
          <div className="text-thin">
            Optimizing our list of coaches near you
          </div>
        </div>
      </div>
    );
  } else {
    return <div>{screenToDisplay}</div>;
  }
};

export default withRouter(CoachList);
