import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App/App";
import {
  completeEvent,
  createEvent,
  getAthleteCoaches,
  getAthleteEvents,
  getCoachClients,
  getEvents,
  getProfile,
  updateEvent,
  updateProfile,
} from "../../api/api";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import CoachCalenderDialog from "../CoachCalenderEvent/CoachCalenderEvent";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import "./CoachCalendar.scss";
import DateAndTime from "../DateAndTime/DateAndTime";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import CoachCalenderCreate from "../CoachCalenderCreate/CoachCalenderCreate";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import logo from "../HomeNav/AULogoSmall.png";

const TIME_LIMITS = {
  mornings: {
    min: 6,
    max: 11,
  },
  daytime: {
    min: 11,
    max: 17,
  },
  evenings: {
    min: 17,
    max: 23,
  },
  weekends: {
    min: 6,
    max: 23,
  },
};

const localizer = momentLocalizer(moment);

const CoachCalendar = (props) => {
  const { user } = useContext(AuthContext);

  const [eventRefreshKey, setEventRefreshKey] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null);
  const [events, setEvents] = useState([]);
  const [clients, setClients] = useState({});
  const [eventType, setEventType] = useState("");
  const [connections, setConnections] = useState(null);
  const [clientToInvite, setClientToInvite] = useState(null);
  const [pendingClients, setPendingClients] = useState({});
  const [showEventDialog, setShowEventDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [openSnackbarMessage, setOpenSnackbarMessage] = useState(null);
  const [completeEventSnackbarError, setCompleteEventSnackbarError] =
    useState(false);
  const [snackbarTimeSuccess, setSnackbarTimeSuccess] = useState(false);
  const [snackbarTimeError, setSnackbarTimeError] = useState(false);
  const [newEventSnackbarError, setNewEventSnackbarError] = useState(false);
  const [current, setCurrent] = useState(new Date());
  const [eventTitle, setEventTitle] = useState("");
  const [showCompleteDialog, setShowCompleteDialog] = useState(false);
  const [earliestStartDate, setEarliestStartDate] = useState(
    moment(new Date()).startOf("month").toDate()
  );
  const [generalAvailability, setGeneralAvailability] = useState({
    daytime: user.profile.generalAvailability
      ? user.profile.generalAvailability.daytime
      : false,
    mornings: user.profile.generalAvailability
      ? user.profile.generalAvailability.mornings
      : false,
    evenings: user.profile.generalAvailability
      ? user.profile.generalAvailability.evenings
      : false,
    weekends: user.profile.generalAvailability
      ? user.profile.generalAvailability.weekends
      : false,
  });
  const [generalTimes, setGeneralTimes] = useState({
    daytime: user.profile.generalAvailability
      ? {
          From: new Date(user.profile.daytime.From),
          To: new Date(user.profile.daytime.To),
        }
      : { From: new Date(), To: new Date() },
    mornings: user.profile.generalAvailability
      ? {
          From: new Date(user.profile.mornings.From),
          To: new Date(user.profile.mornings.To),
        }
      : { From: new Date(), To: new Date() },
    evenings: user.profile.generalAvailability
      ? {
          From: new Date(user.profile.evenings.From),
          To: new Date(user.profile.evenings.To),
        }
      : { From: new Date(), To: new Date() },
    weekends: user.profile.generalAvailability
      ? {
          From: new Date(user.profile.weekends.From),
          To: new Date(user.profile.weekends.To),
        }
      : { From: new Date(), To: new Date() },
  });
  const [profileTimeUpdates, setProfileTimeUpdates] = useState({
    daytime: user.profile.generalAvailability
      ? {
          From: new Date(user.profile.daytime.From),
          To: new Date(user.profile.daytime.To),
        }
      : { From: new Date(), To: new Date() },
    mornings: user.profile.generalAvailability
      ? {
          From: new Date(user.profile.mornings.From),
          To: new Date(user.profile.mornings.To),
        }
      : { From: new Date(), To: new Date() },
    evenings: user.profile.generalAvailability
      ? {
          From: new Date(user.profile.evenings.From),
          To: new Date(user.profile.evenings.To),
        }
      : { From: new Date(), To: new Date() },
    weekends: user.profile.generalAvailability
      ? {
          From: new Date(user.profile.weekends.From),
          To: new Date(user.profile.weekends.To),
        }
      : { From: new Date(), To: new Date() },
  });
  const [newEventTimes, setNewEventTimes] = useState({
    newEvent: {
      From: new Date(),
      To: new Date(new Date().valueOf() + 3600000),
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    user.firebaseUser.getIdToken().then(function (idToken) {
      if (user.profile.profileType === "coach") {
        getEvents(idToken, user.firebaseUser.uid, earliestStartDate.valueOf())
          .then((response) => {
            console.log("events", response);
            setEvents(
              response["events"].concat(
                response["pendingEvents"].concat(response["personalEvents"])
              )
            );
            setClients(response["clients"]);
            setPendingClients(response["pendingClients"]);
          })
          .catch((e) => console.log(e));
      } else {
        getAthleteEvents(
          idToken,
          user.firebaseUser.uid,
          earliestStartDate.valueOf()
        )
          .then((response) => {
            setEvents(
              response["events"].concat(
                response["pendingEvents"].concat(response["personalEvents"])
              )
            );
            setClients(response["coaches"]);
            setPendingClients(response["pendingCoaches"]);
          })
          .catch((e) => console.log(e));
      }
    });
  }, [
    earliestStartDate,
    user.firebaseUser,
    user.profile.profileType,
    eventRefreshKey,
  ]);

  useEffect(() => {
    user.firebaseUser.getIdToken().then(function (idToken) {
      if (user.profile.profileType === "coach") {
        getCoachClients(idToken, user.firebaseUser.uid)
          .then((response) => {
            setConnections(response["clients"]);
          })
          .catch((error) => console.log(error));
      } else {
        getAthleteCoaches(idToken, user.firebaseUser.uid)
          .then((response) => {
            setConnections(response["coaches"]);
          })
          .catch((error) => console.error(error));
      }
    });
  }, [user.firebaseUser, user.profile]);

  useEffect(() => {
    user.firebaseUser.getIdToken().then(function (idToken) {
      getProfile(idToken, user.firebaseUser.uid)
        .then((response) => {
          if (response.generalAvailability) {
            setGeneralAvailability({
              daytime: response.generalAvailability.daytime,
              mornings: response.generalAvailability.mornings,
              evenings: response.generalAvailability.evenings,
              weekends: response.generalAvailability.weekends,
            });
            setGeneralTimes({
              daytime: response.generalAvailability.daytime
                ? {
                    From: new Date(response.daytime.From),
                    To: new Date(response.daytime.To),
                  }
                : { From: new Date(), To: new Date() },
              mornings: response.generalAvailability.mornings
                ? {
                    From: new Date(response.mornings.From),
                    To: new Date(response.mornings.To),
                  }
                : { From: new Date(), To: new Date() },
              evenings: response.generalAvailability.evenings
                ? {
                    From: new Date(response.evenings.From),
                    To: new Date(response.evenings.To),
                  }
                : { From: new Date(), To: new Date() },
              weekends: response.generalAvailability.weekends
                ? {
                    From: new Date(response.weekends.From),
                    To: new Date(response.weekends.To),
                  }
                : { From: new Date(), To: new Date() },
            });
          }
        })
        .catch((e) => console.log(e));
    });
  }, [user.firebaseUser]);

  const getProfileTimes = () => {
    user.firebaseUser.getIdToken().then(function (idToken) {
      getProfile(idToken, user.firebaseUser.uid)
        .then((response) => {
          if (response.generalAvailability) {
            setGeneralAvailability({
              daytime: response.generalAvailability.daytime,
              mornings: response.generalAvailability.mornings,
              evenings: response.generalAvailability.evenings,
              weekends: response.generalAvailability.weekends,
            });
            setGeneralTimes({
              daytime: response.generalAvailability.daytime
                ? {
                    From: new Date(response.daytime.From),
                    To: new Date(response.daytime.To),
                  }
                : { From: new Date(), To: new Date() },
              mornings: response.generalAvailability.mornings
                ? {
                    From: new Date(response.mornings.From),
                    To: new Date(response.mornings.To),
                  }
                : { From: new Date(), To: new Date() },
              evenings: response.generalAvailability.evenings
                ? {
                    From: new Date(response.evenings.From),
                    To: new Date(response.evenings.To),
                  }
                : { From: new Date(), To: new Date() },
              weekends: response.generalAvailability.weekends
                ? {
                    From: new Date(response.weekends.From),
                    To: new Date(response.weekends.To),
                  }
                : { From: new Date(), To: new Date() },
            });
          }
        })
        .catch((e) => console.log(e));
    });
  };

  const updateAvailability = (e) => {
    e.preventDefault();
    const allUpdates = {
      generalAvailability: { ...generalAvailability },
    };
    for (var slot in generalAvailability) {
      if (generalAvailability[slot]) {
        if (
          profileTimeUpdates[slot]["From"].valueOf() >
          profileTimeUpdates[slot]["To"].valueOf() - 3600000 / 4
        ) {
          console.log(generalAvailability);
          setSnackbarTimeError(true);
          getProfileTimes();
          return;
        }
        // if (((profileTimeUpdates[slot]['From'].getHours() + ((profileTimeUpdates[slot]['From'].getMinutes())/60)) < TIME_LIMITS[slot]['min']) ||
        //     ((profileTimeUpdates[slot]['To'].getHours() + ((profileTimeUpdates[slot]['To'].getMinutes())/60)) > TIME_LIMITS[slot]['max'])) {
        //   setSnackbarTimeError(true); //Change to show Dialog that shows min and max times
        //   return;
        // }
        allUpdates[slot] = profileTimeUpdates[slot];
        allUpdates[slot]["From"] = profileTimeUpdates[slot]["From"].valueOf();
        allUpdates[slot]["To"] = profileTimeUpdates[slot]["To"].valueOf();
      }
      allUpdates[slot] = generalTimes[slot];
      allUpdates[slot]["From"] = generalTimes[slot]["From"].valueOf();
      allUpdates[slot]["To"] = generalTimes[slot]["To"].valueOf();
    }
    user.firebaseUser.getIdToken().then(function (idToken) {
      updateProfile(idToken, allUpdates, user.firebaseUser.uid)
        .then((response) => {
          setSnackbarTimeSuccess(true);
          getProfileTimes();
        })
        .catch((e) => console.log(e));
    });
  };

  const submitNewEvent = (e) => {
    e.preventDefault();
    let eventToSubmit = {};
    let eventStatus = "";
    if (eventType === "") {
      setNewEventSnackbarError(true);
      return;
    }
    if (
      eventType === "Normal Session" &&
      (!clientToInvite || clientToInvite === "")
    ) {
      setNewEventSnackbarError(true);
      return;
    }
    if (
      newEventTimes["newEvent"]["From"].valueOf() >
      newEventTimes["newEvent"]["To"].valueOf() - 3600000 / 4
    ) {
      setNewEventSnackbarError(true);
      return;
    }
    if (eventType === "Personal") {
      eventStatus = "personal";
      eventToSubmit = {
        startTime: newEventTimes["newEvent"]["From"].valueOf(),
        endTime: newEventTimes["newEvent"]["To"].valueOf(),
        user: user.firebaseUser.uid,
        status: eventStatus,
        title: eventTitle,
      };
    } else if (eventType === "Normal Session") {
      eventStatus = "pending";
      eventToSubmit = {
        startTime: newEventTimes["newEvent"]["From"].valueOf(),
        endTime: newEventTimes["newEvent"]["To"].valueOf(),
        coach:
          user.profile.profileType === "coach"
            ? user.firebaseUser.uid
            : clientToInvite,
        athlete:
          user.profile.profileType === "coach"
            ? clientToInvite
            : user.firebaseUser.uid,
        status: eventStatus,
        athleteStatus:
          user.profile.profileType === "coach" ? "pending" : "accepted",
        coachStatus:
          user.profile.profileType === "coach" ? "accepted" : "pending",
        paymentWaiting: false,
        connectionID: connections[clientToInvite]["connectionID"],
      };
    }
    user.firebaseUser.getIdToken().then(function (idToken) {
      createEvent(idToken, eventToSubmit, eventStatus, user.profile.profileType)
        .then((response) => {
          setEventRefreshKey(eventRefreshKey + 1);
          setSuccessMessage(
            "Your session request has been sent. Once it's accepted, you will be notified via email and your AU calendar will update."
          );
        })
        .catch((e) => {
          setOpenSnackbarMessage(e);
          setOpenSnackBar(true);
        });
    });
  };

  const calenderRangeChangeHandler = (date, view) => {
    let start, end;
    if (view === "day") {
      start = moment(date).startOf("day").toDate();
      end = moment(date).endOf("day").toDate();
    } else if (view === "month") {
      start = moment(date).startOf("month").toDate();
      end = moment(date).endOf("month").toDate();
    } else if (view === "week") {
      start = moment(date).startOf("week").toDate();
      end = moment(date).endOf("week").toDate();
    } else if (view === "agenda") {
      start = moment(date).startOf("day").toDate();
      end = moment(date).endOf("day").add(1, "month").toDate();
    }
    console.log(start, end);
    if (start.valueOf() < earliestStartDate.valueOf()) {
      setEarliestStartDate(start);
    }
  };

  const eventActionHandler = (eventID, updates, updater) => {
    if (updates["startTime"] > updates["endTime"] - 3600000 / 4) {
      setNewEventSnackbarError(true);
      return;
    }
    user.firebaseUser.getIdToken().then(function (idToken) {
      updateEvent(idToken, eventID, updates, updater)
        .then((response) => {
          setEventRefreshKey(eventRefreshKey + 1);
          setSuccessMessage("Session successfully updated.");
          setShowEventDialog(false);
        })
        .catch((e) => {
          setShowEventDialog(false);
          setOpenSnackbarMessage(e);
          setOpenSnackBar(true);
        });
    });
  };

  const markEventComplete = (eventID, updates, completer) => {
    user.firebaseUser.getIdToken().then(function (idToken) {
      completeEvent(idToken, eventID, updates, completer)
        .then((response) => {
          setShowCompleteDialog(true);
        })
        .catch((e) => {
          setCompleteEventSnackbarError(true);
          setShowEventDialog(false);
        });
    });
  };

  function getFirstLastNamePair(user) {
    if (user["parentFirstName"]) {
      return user["parentFirstName"] + " " + user["parentLastName"];
    } else {
      return user["firstName"] + " " + user["lastName"];
    }
  }

  const nameKey = user.profile.profileType === "coach" ? "athlete" : "coach";
  return (
    <div className="calendarPageWrapper">
      {showEventDialog ? (
        <CoachCalenderDialog
          open={showEventDialog}
          setOpen={setShowEventDialog}
          selectedEvent={selectedEvent}
          completeEvent={markEventComplete}
          updateEvent={eventActionHandler}
          for={user.profile.profileType}
          name={
            selectedEvent["user"]
              ? null
              : selectedEvent["status"] === "accepted" ||
                  selectedEvent["status"] === "completed"
                ? getFirstLastNamePair(clients[selectedEvent[nameKey]])
                : getFirstLastNamePair(pendingClients[selectedEvent[nameKey]])
          }
        />
      ) : null}
      <Snackbar open={openSnackbar} onClose={(e) => setOpenSnackBar(false)}>
        <Alert
          variant="filled"
          onClose={(e) => setOpenSnackBar(false)}
          severity="error"
        >
          {openSnackbarMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={successMessage} onClose={(e) => setSuccessMessage(null)}>
        <Alert
          variant="filled"
          onClose={(e) => setSuccessMessage(null)}
          severity="success"
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={completeEventSnackbarError}
        onClose={(e) => setCompleteEventSnackbarError(false)}
      >
        <Alert
          variant="filled"
          onClose={(e) => setCompleteEventSnackbarError(false)}
          severity="error"
        >
          There was an error completing this event.
        </Alert>
      </Snackbar>

      <Snackbar
        open={snackbarTimeSuccess}
        onClose={(e) => setSnackbarTimeSuccess(false)}
      >
        <Alert
          variant="filled"
          elevation={10}
          onClose={(e) => setSnackbarTimeSuccess(false)}
          severity="success"
        >
          Availability Updated Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarTimeError}
        onClose={(e) => setSnackbarTimeError(false)}
      >
        <Alert
          variant="filled"
          elevation={10}
          onClose={(e) => setSnackbarTimeError(false)}
          severity="error"
        >
          Available times must be chronological and within reasonable limits.
        </Alert>
      </Snackbar>
      <Snackbar
        open={newEventSnackbarError}
        onClose={(e) => setNewEventSnackbarError(false)}
      >
        <Alert
          variant="filled"
          elevation={10}
          onClose={(e) => setNewEventSnackbarError(false)}
          severity="error"
        >
          All fields must be filled and times must be chronological.
        </Alert>
      </Snackbar>

      <div></div>
      <div className="CoachCalendarView">
        {user && user.profile ? (
          <CoachCalenderCreate
            slot="newEvent"
            min={0.01}
            max={23.99}
            for={user.profile.profileType}
            currentTimes={newEventTimes["newEvent"]}
            allCurrentTimes={newEventTimes}
            setAllCurrentTimes={setNewEventTimes}
            updates={newEventTimes}
            setUpdates={setNewEventTimes}
            createEvent={submitNewEvent}
            clients={connections}
            clientToInvite={clientToInvite}
            setClientToInvite={setClientToInvite}
            eventType={eventType}
            setEventType={setEventType}
            eventTitle={eventTitle}
            setEventTitle={setEventTitle}
            profile={user.profile}
            showDatePicker={true}
          />
        ) : null}
        <div style={{ minHeight: "800px", flexGrow: 2 }}>
          <Calendar
            events={events}
            titleAccessor={(event) => {
              if (event["user"]) {
                return event["title"] === ""
                  ? "Personal Event"
                  : "Personal: " + event["title"];
              } else {
                if (clients[event[nameKey]]) {
                  return (
                    "1 hr session w/ " +
                    getFirstLastNamePair(clients[event[nameKey]])
                  );
                } else if (pendingClients[event[nameKey]]) {
                  return (
                    "1 hr session w/ " +
                    getFirstLastNamePair(pendingClients[event[nameKey]])
                  );
                } else {
                  return "1 hr session";
                }
              }
            }}
            startAccessor={(event) => new Date(event["startTime"])}
            endAccessor={(event) => new Date(event["endTime"])}
            defaultDate={moment().toDate()}
            localizer={localizer}
            onSelectEvent={(event) => {
              setSelectedEvent(event);
              setShowEventDialog(true);
            }}
            onView={(view) => {
              calenderRangeChangeHandler(current, view);
            }}
            onNavigate={(date, view) => {
              setCurrent(date);
              calenderRangeChangeHandler(date, view);
            }}
            eventPropGetter={(event, start, end, isSelected) => {
              let classValue = "";
              if (event["status"] && event["status"] === "pending") {
                classValue = "pending";
              } else if (event["status"] && event["status"] === "canceled") {
                classValue = "canceled";
              }
              return {
                className: classValue,
              };
            }}
          />
        </div>
      </div>
      {user.profile && user.profile.profileType === "coach" ? (
        <div className="CoachCalenderGeneralAvailability">
          <div>
            <h4>Edit Availability</h4>
            <p className="text-thin">
              Select which time windows are available for coaching sessions.
            </p>
            <div className="CoachCalenderGeneralAvailabilityMain">
              <div>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={generalAvailability["daytime"]}
                        onChange={(e) => {
                          setGeneralAvailability({
                            ...generalAvailability,
                            daytime: e.target.checked,
                          });
                        }}
                        name="Daytime"
                      />
                    }
                    label="Daytime"
                  />
                </FormControl>
                {generalAvailability["daytime"] ? (
                  <DateAndTime
                    slot="daytime"
                    min={TIME_LIMITS["daytime"]["min"]}
                    max={TIME_LIMITS["daytime"]["max"]}
                    currentTimes={generalTimes["daytime"]}
                    allCurrentTimes={generalTimes}
                    setAllCurrentTimes={setGeneralTimes}
                    updates={profileTimeUpdates}
                    setUpdates={setProfileTimeUpdates}
                    showDatePicker={false}
                  />
                ) : null}
              </div>
              <div>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={generalAvailability["evenings"]}
                        onChange={(e) => {
                          setGeneralAvailability({
                            ...generalAvailability,
                            evenings: e.target.checked,
                          });
                        }}
                        name="Evenings"
                      />
                    }
                    label="Evenings"
                  />
                </FormControl>
                {generalAvailability["evenings"] ? (
                  <DateAndTime
                    slot="evenings"
                    min={TIME_LIMITS["evenings"]["min"]}
                    max={TIME_LIMITS["evenings"]["max"]}
                    currentTimes={generalTimes["evenings"]}
                    allCurrentTimes={generalTimes}
                    setAllCurrentTimes={setGeneralTimes}
                    updates={profileTimeUpdates}
                    setUpdates={setProfileTimeUpdates}
                    showDatePicker={false}
                  />
                ) : null}
              </div>
              <div>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={generalAvailability["mornings"]}
                        onChange={(e) => {
                          setGeneralAvailability({
                            ...generalAvailability,
                            mornings: e.target.checked,
                          });
                        }}
                        name="Mornings"
                      />
                    }
                    label="Mornings"
                  />
                </FormControl>
                {generalAvailability["mornings"] ? (
                  <DateAndTime
                    slot="mornings"
                    min={TIME_LIMITS["mornings"]["min"]}
                    max={TIME_LIMITS["mornings"]["max"]}
                    currentTimes={generalTimes["mornings"]}
                    allCurrentTimes={generalTimes}
                    setAllCurrentTimes={setGeneralTimes}
                    updates={profileTimeUpdates}
                    setUpdates={setProfileTimeUpdates}
                    showDatePicker={false}
                  />
                ) : null}
              </div>
              <div>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={generalAvailability["weekends"]}
                        onChange={(e) => {
                          setGeneralAvailability({
                            ...generalAvailability,
                            weekends: e.target.checked,
                          });
                        }}
                        name="Weekends"
                      />
                    }
                    label="Weekends"
                  />
                </FormControl>
                {generalAvailability["weekends"] ? (
                  <DateAndTime
                    slot="weekends"
                    min={TIME_LIMITS["weekends"]["min"]}
                    max={TIME_LIMITS["weekends"]["max"]}
                    currentTimes={generalTimes["weekends"]}
                    allCurrentTimes={generalTimes}
                    setAllCurrentTimes={setGeneralTimes}
                    updates={profileTimeUpdates}
                    setUpdates={setProfileTimeUpdates}
                    showDatePicker={false}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="CoachCalenderSaveAndUndo">
            {!generalAvailability["daytime"] &&
            !generalAvailability["weekends"] &&
            !generalAvailability["mornings"] &&
            !generalAvailability["evenings"] ? null : (
              <Button className="buttonSecondary">Refresh</Button>
            )}
            {!generalAvailability["daytime"] &&
            !generalAvailability["weekends"] &&
            !generalAvailability["mornings"] &&
            !generalAvailability["evenings"] ? null : (
              <Button
                className="buttonPrimary"
                onClick={(e) => updateAvailability(e)}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      ) : null}

      <Dialog
        open={showCompleteDialog}
        onClose={(e) => {
          window.location = "/calendar";
        }}
      >
        <DialogContent dividers>
          <div className="successDialogContent">
            <img src={logo} alt="Logo" />
            Thanks for confirming this session! Your payment has been triggered
            and will hit your account soon. You can see the update now on your
            payments tab.
            <Button
              className="buttonPrimary"
              onClick={() => {
                window.location = "/calendar";
              }}
            >
              Continue
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CoachCalendar;
