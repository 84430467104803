import Button from "@material-ui/core/Button";
import {
  DirectionsRun,
  FiberManualRecord,
  FitnessCenter,
  Home,
  InsertEmoticon,
  SportsBaseball,
  SportsBasketball,
  SportsFootball,
  SportsSoccer,
  Star,
} from "@material-ui/icons";
import React from "react";
import Colors from "../../constants/colors";
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import blank_avatar from "../CoachProfile/avatar.png";
import "./CoachListPanel.scss";

const CoachListPanel = (props) => {
  const homeIcon = <Home style={{ color: Colors.secondary }} />;
  const sportsBasketball = (
    <SportsBasketball style={{ color: Colors.secondary }} />
  );
  const sportsBaseball = <SportsBaseball style={{ color: Colors.secondary }} />;
  const sportsSoccerBall = <SportsSoccer style={{ color: Colors.secondary }} />;
  const sportsDefault = (
    <SportsBasketball style={{ color: Colors.secondary }} />
  );
  const lacrosse = <FiberManualRecord style={{ color: Colors.secondary }} />;
  const softball = <SportsBaseball style={{ color: Colors.secondary }} />;
  const football = <SportsFootball style={{ color: Colors.secondary }} />;
  const trackField = <DirectionsRun style={{ color: Colors.secondary }} />;
  const strengthSpeed = <FitnessCenter style={{ color: Colors.secondary }} />;
  const startIcon = <Star style={{ color: Colors.secondary }} />;
  const insertEmoticon = <InsertEmoticon style={{ color: Colors.secondary }} />;
  let ballIcon = homeIcon;
  switch (
    props.sport
      ? props.sport
      : props.coach.sport.length > 0
        ? props.coach.sport[0]
        : ""
  ) {
    case "basketball":
      ballIcon = sportsBasketball;
      break;
    case "baseball":
      ballIcon = sportsBaseball;
      break;
    case "soccer":
      ballIcon = sportsSoccerBall;
      break;
    case "lacrosse":
      ballIcon = lacrosse;
      break;
    case "softball":
      ballIcon = softball;
      break;
    case "football":
      ballIcon = football;
      break;
    case "track-and-field":
      ballIcon = trackField;
      break;
    case "strength-and-speed":
      ballIcon = strengthSpeed;
      break;
    case "mental-performance":
      ballIcon = insertEmoticon;
      break;
    default:
      ballIcon = sportsDefault;
      break;
  }

  let yearsExp = 0;
  if (
    Array.isArray(props.coach.coachingExp) &&
    props.coach.coachingExp.length > 0
  ) {
    props.coach.coachingExp.forEach((exp) => {
      if (exp.to.length === 0) {
        yearsExp = yearsExp + (new Date().getFullYear() - +exp.from);
      } else {
        yearsExp = yearsExp + (+exp.to - +exp.from);
      }
    });
  }
  const pitch =
    props.coach.elevPitch && props.coach.elevPitch.length > 180
      ? props.coach.elevPitch.substr(0, 180) + "..."
      : props.coach.elevPitch;
  const isSuper =
    (props.coach.reviewSummary.avgRating !== 0 ||
      props.coach.isSuperCoach === true) &&
    props.coach.isSuperCoach !== false;
  console.log("review summary", props.coach.reviewSummary);
  return (
    <div className="CoachListPanelContainer">
      <div className="basicInfo">
        <div>
          {props.coach["profileImage"] ? (
            <img
              src={CLOUDFRONT_UPLOADS + props.coach["profileImage"]}
              alt="Profile"
              className="userAvatar"
            />
          ) : (
            <img src={blank_avatar} alt="Blank Avatar" className="userAvatar" />
          )}
        </div>
        <div className="name">
          {props.coach.firstName}{" "}
          {props.coach.lastName && props.coach.lastName.length > 0
            ? props.coach.lastName.substr(0, 1)
            : ""}
        </div>
        {/*<button className="viewProfileButtonCoachListPanel" onClick={e => props.onClickViewProfile(props.coach)}>View Full Profile</button>*/}
      </div>
      <div className="infoList">
        {isSuper ? (
          <div className="supercoach">
            <div className="inner">Supercoach</div>
          </div>
        ) : null}
        <ul className={isSuper ? "supercoachList" : ""}>
          <li className="location">
            {homeIcon}
            {props.coach.city}, {props.coach.state}
          </li>
          <li className="type">
            {ballIcon} Private {props.sport} Coach
          </li>
          {props.coach.reviewSummary.avgRating ? (
            <li className="played">
              {startIcon}{" "}
              {props.coach.reviewSummary.avgRating === 0
                ? "No Reviews Yet"
                : props.coach.reviewSummary.avgRating.toFixed(1) +
                  "/5 (" +
                  props.coach.reviewSummary.numReviews +
                  " Review" +
                  (props.coach.reviewSummary.numReviews > 1 ? "s" : "") +
                  ")"}
            </li>
          ) : null}
        </ul>
      </div>
      <div className="coachAbout">
        <p className="title">About the Coach</p>
        <p className="about">{pitch}</p>
        <span
          style={{ color: "darkgreen" }}
          onClick={(e) => props.onClickViewProfile(props.coach)}
          className="readMore"
        >
          READ MORE
        </span>
      </div>
      <div className="sessionInfo">
        {props.coach.sessionRate ? (
          <div className="rate">${props.coach.sessionRate} / Session</div>
        ) : null}
        <Button
          className="buttonPrimary"
          onClick={(e) => props.onClickBookSession(props.coach)}
        >
          Book Sessions
        </Button>
        <Button
          className="buttonSecondary"
          onClick={(e) => props.onClickMessageCoach(props.coach)}
        >
          Message Coach
        </Button>
      </div>
    </div>
  );
};

export default CoachListPanel;
