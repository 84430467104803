import React, { useContext, useEffect, useState } from 'react';
import './CoachAccount.scss';
import { AuthContext } from "../App/App";
import { createCheckrCandidate, getProfile, getTimezones, updateProfile, deleteUser } from '../../api/api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AthletePaymentSettings from "../AthletePaymentSettings/AthletePaymentSettings";
import MuiPhoneNumber from "material-ui-phone-number";
// import { ListItemText, InputLabel, Select, FormControl} from '@material-ui/core';
import {Checkbox,  DialogContent } from '@material-ui/core';
import { sports } from 'src/constants/sports';

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


  const icon = <CheckBoxOutlineBlankIcon  />;
  const checkedIcon = <CheckBoxIcon  />;


function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

const CoachAccount = (props) => {
  const { user } = useContext(AuthContext);

  const [firstName, setFirstName] = useState(user.profile.firstName);
  const [lastName, setLastName] = useState(user.profile.lastName);
  const [parentFirstName, setParentFirstName] = useState(user.profile.parentFirstName);
  const [parentLastName, setParentLastName] = useState(user.profile.parentLastname);
  const [email, setEmail] = useState(user.profile.email);
  const [phone, setPhone] = useState(user.profile.phone ? user.profile.phone : '');
  const [sport, setSport] = useState(user.profile.sport);
  const [zipCode, setZipCode] = useState(user.profile.zipCode);
  const [newVals, setNewVals] = useState({});
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [profile, setProfile] = useState(null);
  const [timezones, setTimezones] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState("America/New_York");

  const setStateFunctions = {
    "firstName": setFirstName,
    "lastName": setLastName,
    "parentFirstName": setParentFirstName,
    "parentLastName": setParentLastName,
    "sport": setSport,
    "zipCode": setZipCode,
    "email": setEmail,
    "phone": setPhone
  };

  useEffect(() => {
    const data = {
      "first_name": "John",
      "middle_name": "Alfred",
      "no_middle_name": false,
      "last_name": "Smith",
      "email": "john.smith@gmail.com",
      "phone": 5555555555,
      "zipcode": 19008,
      "dob": "1970-01-22",
      "ssn": "111-11-2001",
      "driver_license_number": "F1112001",
      "driver_license_state": "CA",
      "firebaseID": user.firebaseUser.uid
    };
    /* Should only be called after coach goes through Checkr frontend flow, this is just a test. T
        This part below should not be called in useEffect hook */
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        createCheckrCandidate(idToken, data)
          .then(response => {
            console.log(response);

          })
          .catch(e => console.log(e));
      });
  }, [user.firebaseUser]);

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getProfile(idToken, user.firebaseUser.uid)
          .then(response => {
            setFirstName(response.firstName);
            setLastName(response.lastName);
            setParentFirstName(response.parentFirstName);
            setParentLastName(response.parentLastName);
            setSport(response.sport);
            setZipCode(response.zipCode);
            setProfile(response);
            setPhone(response.phone);
            if (response.timezone) {
              setSelectedTimezone(response.timezone);
            }
            console.log('profile', response);
          })
          .catch(e => console.log(e));
      });
  }, [user.firebaseUser]);

  useEffect(() => {
    getTimezones().then(function (res) {
      setTimezones(res);
      console.log('timezones', res);
    })
  }, [])

  const submitChanges = (e) => {
    e.preventDefault();
    if (!validatePhone(phone)) {
      setSaveError("Please enter a valid phone number");
      return;
    }
    submit(newVals, user.firebaseUser.uid);
  };

  function validatePhone(phone) {
    return !phone || phone.length === 0 || phone.length === 14;
  }

  const submit = (vals, id) => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        updateProfile(idToken, vals, id)
          .then(response => {
            for (const field in response) {
              if (setStateFunctions[field] !== undefined) {
                setStateFunctions[field](response[field]);
              }
            }
            setOpenSnackBar(true);
            retrieveProfile();

          })
          .catch(e => {
            console.log(e);
            if (typeof e === 'string') {
              setSaveError(e);
            } else {
              setSaveError("Error saving.  Please try again.");
            }
          });
      });
  };

  const deleteAccount = () => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        deleteUser(idToken, user.firebaseUser.uid)
          .then(() => {
            window.location.reload();
          })
          .catch(e => {
            console.log(e);
            if (typeof e === 'string') {
              setSaveError(e);
            } else {
              setSaveError("Error deleting account.  Please try again.");
            }
          });
      });
  };

  const updatePassword = (e) => {
    if (!newPassword) {
      setSaveError('Please enter a valid password');
      return;
    }
    if (newPassword !== newPasswordConfirm) {
      setSaveError('Passwords do not match');
      return;
    }

    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        updateProfile(idToken, { password: newPassword }, user.firebaseUser.uid)
          .then(response => {
            setNewPassword('');
            setNewPasswordConfirm('');
            setOpenSnackBar(true);
          })
          .catch(e => {
            if (typeof e === 'string') {
              setSaveError(e);
            } else {
              setSaveError("Error updating password.  Please try again.");
            }
          });
      });
  };

  const retrieveProfile = () => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getProfile(idToken, user.firebaseUser.uid)
          .then(response => {
            setFirstName(response.firstName);
            setLastName(response.lastName);
            setParentFirstName(response.parentFirstName);
            setParentLastName(response.parentLastName);
            setSport(response.sport);
            setZipCode(response.zipCode);

          })
          .catch(e => console.log(e));
      });
  };

  return (
    <div className="accountContainer">
      <h3 className="color-secondary">Account Information</h3>
      <table className="accountInfo">
        <tbody>
          {profile && !profile.isParent ?
            <tr>
              <td className="label">{profile && profile.isParent ? "Athlete first name" : "First name"}</td>
              <td className="value">
                <input
                  type="text"
                  value={firstName}
                  onChange={e => {
                    setFirstName(e.target.value);
                    setNewVals({ ...newVals, "firstName": e.target.value });
                  }}>
                </input>
              </td>
            </tr> : null}
          {profile && !profile.isParent ?
            <tr>
              <td className="label">Last name</td>
              <td className="value">
                <input
                  type="text"
                  value={lastName}
                  onChange={e => {
                    setLastName(e.target.value);
                    setNewVals({ ...newVals, "lastName": e.target.value });
                  }}>
                </input>
              </td>
            </tr>
            : null}
          {profile && profile.isParent ?
            <tr>
              <td className="label">Parent first name</td>
              <td className="value">
                <input
                  type="text"
                  value={parentFirstName}
                  onChange={e => {
                    setParentFirstName(e.target.value);
                    setNewVals({ ...newVals, "parentFirstName": e.target.value });
                  }}>
                </input>
              </td>
            </tr>
            : null}
          {profile && profile.isParent ?
            <tr>
              <td className="label">Parent last name</td>
              <td className="value">
                <input
                  type="text"
                  value={parentLastName}
                  onChange={e => {
                    setParentLastName(e.target.value);
                    setNewVals({ ...newVals, "parentLastName": e.target.value });
                  }}>
                </input>
              </td>
            </tr>
            : null}
          <tr>
            <td className="label">Email</td>
            <td className="value">
              <input
                type="text"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setNewVals({ ...newVals, "email": e.target.value });
                }}>
              </input>
            </td>
          </tr>
          <tr>
            <td className="label">Phone</td>
            <td className="value phone">
              <MuiPhoneNumber
                autoFormat={true}
                disableCountryCode={true}
                defaultCountry={'us'}
                onlyCountries={['us']}
                value={phone}
                onChange={val => {
                  setPhone(val);
                  setNewVals({ ...newVals, "phone": val });
                }}
                name="phone"
                label="Phone"
                variant="outlined"
                helperText={"We'll send you reminders about upcoming training sessions - helpful for busy schedules! You can always opt out."}
                inputProps={{ style: { minWidth: '67.8ch', boxShadow: 'none' } }}
              />
            </td>
          </tr>
          <tr>
            <td className="label">Sports</td>
            <td className="value sport">
              {/* <FormControl>
                <InputLabel className="select-label">Sport</InputLabel>
                <Select
                  value={sport}
                  multiple
                  onChange={e => {
                    setSport(e.target.value);
                    setNewVals({ ...newVals, "sport": e.target.value });
                  }}
                  name="sport"
                  type="sport"
                  label="Sport"
                  labelWidth={100}
                  variant="outlined"
                  renderValue={(selected) => selected.join(', ')}
                >
                  <MenuItem value={"basketball"}>
                    <Checkbox checked={sport && sport.indexOf("basketball") > -1} />
                    <ListItemText primary={"Basketball"} />
                  </MenuItem>
                  <MenuItem value={"soccer"}>
                    <Checkbox checked={sport && sport.indexOf("soccer") > -1} />
                    <ListItemText primary={"Soccer"} />
                  </MenuItem>
                  <MenuItem value={"baseball"}>
                    <Checkbox checked={sport && sport.indexOf("baseball") > -1} />
                    <ListItemText primary={"Baseball"} />
                  </MenuItem>
                  <MenuItem value={"softball"}>
                    <Checkbox checked={sport && sport.indexOf("softball") > -1} />
                    <ListItemText primary={"Softball"} />
                  </MenuItem>
                  <MenuItem value={"field-hockey"}>
                    <Checkbox checked={sport && sport.indexOf("field-hockey") > -1} />
                    <ListItemText primary={"Field Hockey"} />
                  </MenuItem>
                  <MenuItem value={"lacrosse"}>
                    <Checkbox checked={sport && sport.indexOf("lacrosse") > -1} />
                    <ListItemText primary={"Lacrosse"} />
                  </MenuItem>
                  <MenuItem value={"football"}>
                    <Checkbox checked={sport && sport.indexOf("football") > -1} />
                    <ListItemText primary={"Football"} />
                  </MenuItem>
                  <MenuItem value={"track-and-field"}>
                    <Checkbox checked={sport && sport.indexOf("track-and-field") > -1} />
                    <ListItemText primary={"Track & Field"} />
                  </MenuItem>
                  <MenuItem value={"strength-and-speed"}>
                    <Checkbox checked={sport && sport.indexOf("strength-and-speed") > -1} />
                    <ListItemText primary={"Strength & Speed"} />
                  </MenuItem>
                  <MenuItem value={"mental-performance"}>
                    <Checkbox checked={sport && sport.indexOf("mental-performance") > -1} />
                    <ListItemText primary={"Mental Performance"} />
                  </MenuItem>

                </Select>
              </FormControl> */}

              <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={sports}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {
                const selectedValues = newValue.map(option => option.value);
                setSport(selectedValues);
                setNewVals({ ...newVals, sport: selectedValues });
              }}
              renderOption={(props, option, { selected }) => {
                return (
                <li {...props}>
                    <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    />
                    <span style={{fontWeight : 100 , fontFamily: 'Roboto, Helvetica, Arial, sans-serif'}}>
                       {option.label} 
                     </span>
                </li>
                );
            }}
              style={{ width: 400 }}
              renderInput={(params) => (
                  <TextField {...params} 
                  name="sport"
                  type="sport"
                  label=""
                  variant="outlined" 
                  placeholder={sport}
                  />
            )}
            />


            </td>
          </tr>
          <tr>
            <td className="label">Zip Code</td>
            <td className="value">
              <input
                type="text"
                value={zipCode}
                onChange={e => {
                  setZipCode(e.target.value);
                  setNewVals({ ...newVals, "zipCode": e.target.value });
                }}>
              </input>
            </td>
          </tr>
          <tr>
            <td className="label">Timezone</td>
            <td className="value">
              <TextField
                value={selectedTimezone}
                select
                onChange={e => {
                  setSelectedTimezone(e.target.value);
                  setNewVals({ ...newVals, "timezone": e.target.value });
                }}
                name="timezone"
                type="timezone"
                variant="outlined">
                {timezones.map(function (item) { return <MenuItem value={item}>{item}</MenuItem> })}
              </TextField>
            </td>
          </tr>
        </tbody>
      </table>
      <Button className="buttonPrimary" onClick={e => submitChanges(e)}>Save</Button>

      <h3 className="color-secondary">Update Password</h3>
      <table className="accountInfo">
        <tbody>
          <tr>
            <td className="label">New Password</td>
            <td className="value">
              <input
                type="password"
                value={newPassword}
                onChange={e => {
                  setNewPassword(e.target.value);
                }}>
              </input>
            </td>
          </tr>
          <tr>
            <td className="label">Re-Enter Password</td>
            <td className="value">
              <input
                type="password"
                value={newPasswordConfirm}
                onChange={e => {
                  setNewPasswordConfirm(e.target.value);
                }}>
              </input>
            </td>
          </tr>
        </tbody>
      </table>
      <Button className="buttonPrimary" onClick={e => updatePassword(e)}>Update Password</Button>
      {profile && profile.profileType === 'coach' ?
        <div className="backgroundCheckContainer">
          <h3 className="color-secondary">Background Check*</h3>
          <Button className="buttonPrimary" onClick={() => window.location = 'https://athletesuntapped.certn.co/hr/apply/d25bbb81-7140-4758-b307-c68b400effbd'}>Complete Background Check</Button>
          <h5>Click to start your background check!</h5>
          <p>*Completing a one-time background check through our partner company, Certn,
            is required for all AU coaches. If you have completed a background check within
            the past year from another company, or if you have a valid coaching license, please
            email the verified documentation to us: community@athletesuntapped.com</p>
        </div>
        : <AthletePaymentSettings />}
      <Snackbar open={openSnackbar} onClose={e => setOpenSnackBar(false)}>
        <Alert onClose={e => setOpenSnackBar(false)} severity="success">
          Saved Successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={saveError} onClose={e => setSaveError(false)}>
        <Alert onClose={e => setSaveError(null)} severity="error">
          {saveError}
        </Alert>
      </Snackbar>

      <Dialog onClose={() => setShowDeleteConfirm(false)} open={showDeleteConfirm}>
        <h3>Are you sure?</h3>
        <DialogContent className="text-large">
          <b>WARNING!</b>
          This is an irreversible operation.  By deleting your account, you will lose access to Athletes Untapped and all data associated with your account will be lost. Note: full account deletion can take up to 24hrs.
          <br /><br />
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button className="buttonWarn" onClick={e => deleteAccount()}>Delete Account</Button>
            <Button className="buttonPrimary" onClick={e => setShowDeleteConfirm(false)}>Cancel</Button>
          </div>
        </DialogContent>
      </Dialog>

      <br /><br /><br /><br /><br /><br /><br /><br /><br />
      <Button className="buttonWarn" onClick={e => setShowDeleteConfirm(true)}>Delete Account</Button>
    </div>
  );
};

export default CoachAccount;