import React from "react";
import { withRouter } from 'react-router-dom';
import './Signup.scss';
import logo from '../HomeNav/AULogoSmall.png';


const Signup = (props) => {
  const state = {
    forAthlete: false
  };
  let search = null;
  if (props.returnAfterLogin) {
    search = '?returnTo=' + encodeURIComponent(window.location.href);
  }
  const takeToBecomeCoach = (e) => {
    if (props.close) {
      props.close();
    }
    e.preventDefault();
    state.forAthlete = false;
    props.history.push({
      pathname: '/join',
      state: state,
      search: search
    });
  };

  const takeToBecomeAthlete = (e) => {
    if (props.close) {
      props.close();
    }
    e.preventDefault();
    state.forAthlete = true;
    props.history.push({
      pathname: '/join',
      state: state,
      search: search
    });
  };

  return (

    <div className="signupControl">

      <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
        <h4 className="signupHeader">Create a free account to contact coaches!</h4>
        <img src={logo} alt="Athletes Untapped" className="AULogoSignup" />
        <p className="signupText">Sign Up</p>
        <hr style={{ width: '35%', border: '1px solid lightslategray', marginBottom: '1.5rem' }} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <p className="becomeAnAU">Become an AU</p>
      </div>
      <div className="signupButton">
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
          <button onClick={(e) => takeToBecomeCoach(e)}>Coach</button>
          <button onClick={(e) => takeToBecomeAthlete(e)}>Athlete or Parent</button>
        </div>
        <p>Already in the AU Community?</p>
        <p className="pSwitchToLogin" onClick={props.switch}>Log in here</p>
      </div>
    </div>
  );
};

export default withRouter(Signup);
