import React, {useContext, useState} from 'react';
import {Rating} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import {AuthContext} from "../App/App";
import {createReview} from "../../api/api";
import './ReviewForm.scss';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const ReviewForm = props => {
  const [rating, setRating] = useState(props.rating ? props.rating : 0);
  const [comments, setComments] = useState('');
  const [error, setError] = useState(null)
  const {user} = useContext(AuthContext);

  const saveReview = () => {
    if(!rating) {
      setError("Please select a rating");
      return;
    }
    const review = {
      'rating': rating,
      'comments': comments,
      'user': props.coachId,
      'author': user.firebaseUser.uid
    };
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        return createReview(idToken, review).then(() => {
          if (props.onComplete) {
            props.onComplete();
          }
        })
        
      })
      .catch(e => {
        console.log(e);
        if (props.onComplete) {
          props.onComplete();
        }
      });
  };

  return (
    <div className="reviewForm">
      <h4>How would you rate your session?</h4>
      <h6>Rating</h6>
      <Rating value={rating}
              precision={1}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
      />
      <h6>Comments</h6>
      <textarea value={comments} onChange={(e) => setComments(e.target.value)}/>
      <Button onClick={() => saveReview()} className="buttonPrimary">Send</Button>
      <Snackbar open={error !== null}  onClose={e => setError(null)}>
        <Alert variant="filled" onClose={e => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ReviewForm;