import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Login from "../Login/Login";
import Signup from "../Signup/Signup";
import "./LoginSignupDialog.scss";

const LoginSignupDialog = (props) => {
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    setShowLogin(props.showLogin);
  }, [props.showLogin]);

  return (
    <div>
      <Dialog open={props.show} fullWidth maxWidth="sm" onClose={props.close}>
        <DialogContent dividers>
          {showLogin ? (
            <Login
              returnAfterLogin={props.returnAfterLogin}
              switch={() => setShowLogin(!showLogin)}
            />
          ) : (
            <Signup
              returnAfterLogin={props.returnAfterLogin}
              switch={() => setShowLogin(!showLogin)}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withRouter(LoginSignupDialog);
