import React, {useEffect, useState} from 'react';
import {getCoachById} from "../../api/api";
import {useParams} from "react-router";
import CoachProfile from "../CoachProfile/CoachProfile";
import {CircularProgress} from "@material-ui/core";

const CoachProfilePage = props => {
  const {id} = useParams();
  const [coach, setCoach] = useState(null);

  useEffect(() => {
    getCoachById(id)
      .then(response => {
        setCoach(response);
      })
      .catch(error => console.log(error));
  }, [id]);

  //TODO: coach not found treatment
  return (
    <div>
      {coach ?
        <CoachProfile
          public={true}
          user={{'profile': coach}}
        />
      : <CircularProgress/>}
    </div>
  );
};

export default CoachProfilePage;