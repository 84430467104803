import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useContext, useEffect, useState } from 'react';
import { awsPresignedPost, getCompletedEvents } from '../../api/api';
import { AuthContext } from "../App/App";
import '../CoachVideos/CoachVideos.scss';
import CoachVideosClientPanel from '../CoachVideosClientPanel/CoachVideosClientPanel';
import CoachVideosClientView from '../CoachVideosClientView/CoachVideosClientView';
import logo from "../HomeNav/AULogoSmall.png";
import SearchForCoaches from '../SearchForCoaches/SearchForCoaches';


const AthleteVideos = props => {
  const {user} = useContext(AuthContext);
  const [uploadData, setUploadData] = useState({
    'uploadedBy': user.firebaseUser.uid,
    'uploadDate': null,
    'bucketName': 'athletes-untapped-prod-uploads',

  });
  const [fileToUpload, setFileToUpload] = useState(null);
  const [sessionsByCoach, setSessionsByCoach] = useState({});
  const [coaches, setCoaches] = useState({});
  const [videoUploadSelectedClient, setVideoUploadSelectedClient] = useState(null);
  const [videoUploadSelectedSession, setVideoUploadSelectedSession] = useState(null);
  const [showAddVideoDialog, setShowAddVideoDialog] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getCompletedEvents(idToken, user.firebaseUser.uid, 'athlete')
          .then(response => {
            setSessionsByCoach(response['completedEventsByClient']);
            setCoaches(response['clients']);
            console.log('completed events', response);
          })
          .catch(e => console.log(e));
      });
  }, [user.firebaseUser]);

  const onUploadChange = e => {
    console.log(e.target.files[0]);
    setUploadData({
      ...uploadData,
      'uploadDate': e.target.files[0] ? e.target.files[0]['lastModified'] : null,
      'objectKey': e.target.files[0] ? ('videos/' + e.target.files[0]['name']) : null,
    });
    setFileToUpload(e.target.files[0]);

  };

  const uploadFile = (e) => {
    e.preventDefault();

    if (!uploadData['uploadDate'] || !uploadData['objectKey']) {
      console.log('Pick a file to upload.');
      return;
    }
    setUploadData({
      ...uploadData,

    });
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        awsPresignedPost(idToken, uploadData)
          .then(response => {
            console.log(response);
            sendToAWS(response['url']);
            //callback(response['url'])
          })
          .catch(e => console.log(e));
      });
  };

  // const getSignedURL = (file, callback) => {
  //     console.log(file)


  //     const uploadinfo = {
  //         'uploadDate': file['lastModified'],
  //         'objectKey': 'videos/' + file['name'],
  //     }

  //     user.firebaseUser.getIdToken()
  //         .then(function(idToken) {
  //             awsPresignedPost(idToken, uploadinfo)
  //             .then(response => {
  //                 console.log(response)

  //                 callback({'signedUrl':response['url']})
  //             })
  //             .catch(e => console.log(e));
  //         });
  // }

  const sendToAWS = async url => {
    const response = await fetch(
      new Request(url, {
        method: 'PUT',
        body: fileToUpload,
        headers: new Headers({
          'Content-Type': 'video/quicktime',
        }),
      }),
    );
    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      console.log(response);
    } else {
      console.log(response);
    }
    /*awsUploadFile(url, fileToUpload)
    .then(response => {
        console.log(response);
    })
    .catch(e => console.log(e));*/
  };

  const addVideo = (clientId, sessionId) => {
    setVideoUploadSelectedClient(clientId);
    setVideoUploadSelectedSession(sessionId);
    setShowAddVideoDialog(true);
  };

  const onAddVideoDialogClose = () => {
    setShowAddVideoDialog(false);
  };

  return (
    <div className="videos">
      <div>
        <div className="videoPageIntro">
          <img alt="logo" src={logo}/>
          <div className="centerContent">
            <h5>Finally, a coaching platform that offers video feedback!</h5>
            <p>Our platform allows you to track your athletic progress. Watch yourself on video while at home to understand what areas of your game require extra attention.</p>
            <h5>Here's how it works:</h5>
            <ol>
              <li>As a parent, take a video (up to 60 seconds in length) on your phone OR ask your AU coach to help by taking the video.</li>
              <li>Upload the video below by clicking <b>Add Video</b> next to a session.</li>
              <li>Ask your AU coach to leave comments on the video so you can watch yourself in action, dissect the weak (or strong) parts of your game, and prepare to make adjustments for your next training session.</li>
            </ol>
          </div>
        </div>

      </div>
      {coaches && sessionsByCoach ?
        <div className="sessionList">
          {Object.keys(sessionsByCoach).map((id, index) => {
            if (coaches[id]) {
              return (
                <CoachVideosClientView
                  for="athlete"
                  client={coaches[id]}
                  clientID={id}
                  clientSessions={sessionsByCoach[id]}
                  addVideo={addVideo}
                />
              );
            } else {
              return null;
            }
          })}
        </div> : <CircularProgress/>}
      <Dialog onClose={onAddVideoDialogClose} aria-labelledby="customized-dialog-title" open={showAddVideoDialog} maxWidth="md" fullWidth={true}>
        <DialogTitle id="customized-dialog-title" onClose={onAddVideoDialogClose}>
          Upload Video
        </DialogTitle>
        <DialogContent dividers>
          <CoachVideosClientPanel
            for="athlete"
            onPickFile={onUploadChange}
            onSubmit={uploadFile}
            client={coaches[videoUploadSelectedClient]}
            clientID={videoUploadSelectedClient}
            clientSessions={sessionsByCoach[videoUploadSelectedClient]}
            selectedSession={videoUploadSelectedSession}
            setShowAddVideoDialog={setShowAddVideoDialog}
          />
        </DialogContent>
      </Dialog>
      <div className="lookingForNew">
        <h6>Looking for a New Private Coach in Your Area?</h6>
        <SearchForCoaches/>
      </div>
    </div>
  );
};

export default AthleteVideos;