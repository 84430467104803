import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../App/App";
import {createPaymentMethod, getPaymentMethods} from '../../api/api';
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './AthletePaymentSettings.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";


function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

const AthletePaymentSettings = (props) => {
  const {user} = useContext(AuthContext);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState('');
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [listOfPaymentMethods, setListOfPaymentMethods] = useState([]);
  const stripe = useStripe();
  const [nameOnCard, setNameOnCard] = useState("");
  const elements = useElements();

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getPaymentMethods(idToken, user.firebaseUser.uid)
          .then(response => {
            setListOfPaymentMethods(response['paymentMethods']);
            console.log(response);
          })
          .catch(e => console.log(e));
      });
  }, [user.firebaseUser]);

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        createPaymentMethod(idToken, {'id': user.firebaseUser.uid})
          .then(response => {
            setClientSecret(response['clientSecret']);
          })
          .catch(e => console.log(e));
      });
  }, [user.firebaseUser]);

  const getAllCards = () => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getPaymentMethods(idToken, user.firebaseUser.uid)
          .then(response => {
            setListOfPaymentMethods(response['paymentMethods']);
          })
          .catch(e => console.log(e));
      });
  };


  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          'name': nameOnCard
        },
      }
    });

    if (result.error) {
      console.log("error");
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      setNameOnCard("");
      getAllCards();
    }
  };


  return (
    <div className="CoachAccountContainer">
      <h3 className="color-secondary">Payment Methods</h3>

      <h5 className="text-center">Current Payment Methods</h5>
      <div style={{display: 'flex', 'justifyContent': 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {listOfPaymentMethods.map((method, index) => {
            return (
              <div className="paymentMethod">
                <div key={method['id']} className="AthletePaymentSettingsCurrentCardsList">
                  <p>{method['card']['brand'].slice(0, 1).toUpperCase() + method['card']['brand'].slice(1)} {" ************"}{method['card']['last4']}</p>
                  <p>{method['billing_details']['name']}</p>
                  <p>Expires {method['card']['exp_month'] >= 10 ? method['card']['exp_month'].toString() : '0' + method['card']['exp_month']}
                    /{method['card']['exp_year'].toString().slice(2)}</p>
                  <span className="link">Remove</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <h5 className="text-center">Add Payment Method</h5>

      <Snackbar open={openSnackbar}  onClose={e => setOpenSnackBar(false)}>
        <Alert onClose={e => setOpenSnackBar(false)} severity="success">
          Saved Successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={succeeded}  onClose={e => setSucceeded(false)}>
        <Alert onClose={e => setSucceeded(false)} severity="success">
          Card Added Successfully!
        </Alert>
      </Snackbar>
      <div style={{display: 'flex', 'justifyContent': 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div>
            <input
              className="AthletePaymentSettingsNameOnCard"
              type="text"
              value={nameOnCard}
              placeholder={"Name on Card"}
              onChange={e => setNameOnCard(e.target.value)}>
            </input>
          </div>
          <form id="payment-form">
            <CardElement id="card-element" onChange={handleChange}/>
            {/* Show any error that happens when processing the payment */}
            {error && (
              <MuiAlert severity="error" className="CardEntryError">
                {error}
              </MuiAlert>
            )}


            {processing ?
              <CircularProgress size={20}/> :
              <Button
                className="buttonPrimary addPaymentBtn"
                onClick={e => handleSubmit(e)}
                disabled={processing || disabled || succeeded}
              >Add</Button>}

          </form>
        </div>
      </div>
    </div>
  );
};

export default AthletePaymentSettings;