export function calculateSessionRateWithAdditionalAthletes({ sessionRateString, additionalAthletesRateString, numAthletes }) {
    const sessionRate = Number(sessionRateString);
    const additionalAthletesRate = Number(
        additionalAthletesRateString ?? 0);

    if (numAthletes < 2) {
        return sessionRate;
    }

    return sessionRate + (additionalAthletesRate * (numAthletes - 1));
}
