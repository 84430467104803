import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "../App/App";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { getAthleteCoaches, getCompletedEvents } from '../../api/api';
import SearchForCoaches from '../SearchForCoaches/SearchForCoaches';
import '../CoachClients/CoachClients.css';
import { CircularProgress, Dialog } from '@material-ui/core';
import './AthleteCoaches.scss';
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import blank_avatar from "../CoachProfile/avatar.png";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import ReviewForm from "../ReviewForm/ReviewForm";


const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#006400'
    }
  }
});



const AthleteCoaches = (props) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [loadingCoaches, setLoadingCoaches] = useState(true);
  const [coaches, setCoaches] = useState(null);
  const [completedSessions, setCompletedSessions] = useState({});
  const [promptForReview, setShowPromptForReview] = useState(null);

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getCompletedEvents(idToken, user.firebaseUser.uid, 'athlete')
          .then(response => {
            setCompletedSessions(response['completedEventsByClient']);
            //setSessionsByCoach(response['completedEventsByClient'])
          })
          .catch(e => console.log(e));
      });
  }, [user.firebaseUser]);


  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getAthleteCoaches(idToken, user.firebaseUser.uid)
          .then(response => {
            setCoaches(response['coaches']);
            console.log('coaches', response['coaches']);
            setLoadingCoaches(false);
          })
          .catch(error => console.log(error));
      });
  }, [user.firebaseUser]);


  return (
    <div className="coachListWrapper">
      {loadingCoaches ?
        <div style={{ display: 'flex', justifyContent: 'center', padding: '4rem 0rem' }}>
          <ThemeProvider theme={materialTheme}>
            <CircularProgress />
          </ThemeProvider>
        </div> : null}
      {!loadingCoaches && !coaches ?
        <div className="lookingForNew">
          <p>You don't have any coaches yet. Search for a private coach in your area below!</p>
          <SearchForCoaches />
        </div> : null}
      {!loadingCoaches && coaches ?
        <div>
          <h4>Your Coaches</h4>
          <div>
            <TableContainer className='fullSizeBookingsTable'>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Coach Name</TableCell>
                    <TableCell align="right">Last Session</TableCell>
                    <TableCell align="right">Total Sessions</TableCell>
                    <TableCell align="right">Completed</TableCell>
                    <TableCell align="right">Upcoming</TableCell>
                    <TableCell align="right">Pending</TableCell>
                    <TableCell align="right">Need to Schedule</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coaches ? Object.keys(coaches).map((coachID, i) => {
                    const sessionFormattedDate = completedSessions[coachID] && completedSessions[coachID].length > 0 ? new Date(completedSessions[coachID][0]['startTime']).toLocaleDateString() : 'N/A';
                    return (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          {coaches[coachID]['coachInfo']['profileImage'] ?
                            <img src={CLOUDFRONT_UPLOADS + coaches[coachID]['coachInfo']['profileImage']} alt="Profile" className="userAvatar" />
                            :
                            <img src={blank_avatar} alt="Blank Avatar" className="userAvatar" />
                          }
                          <br />
                          <span className="link" onClick={() => {
                            history.push('/coach-profile/' + (coaches[coachID].coachInfo.customUrl ? coaches[coachID].coachInfo.customUrl : coachID));
                          }}>{coaches[coachID]['coachInfo']['firstName'] + ' ' + coaches[coachID]['coachInfo']['lastName']}</span>
                        </TableCell>
                        <TableCell align="right">{sessionFormattedDate}</TableCell>
                        <TableCell align="right">{coaches[coachID]['connection']['numSessions']}</TableCell>
                        <TableCell align="right">{coaches[coachID]['connection']['sessionsCompleted']}</TableCell>
                        <TableCell align="right">{coaches[coachID]['connection']['acceptedSessions']}</TableCell>
                        <TableCell align="right">{coaches[coachID]['connection']['pendingSessions']}</TableCell>
                        <TableCell align="right">{coaches[coachID]['connection']['sessionsLeft']}</TableCell>
                        <TableCell align="right" className="athleteCoachActions">
                          {coaches[coachID]['connection']['sessionsLeft'] === 0 ?
                            <Button className="buttonPrimary" onClick={() => {
                              history.push('/book-session/' + (coaches[coachID].coachInfo.customUrl ? coaches[coachID].coachInfo.customUrl : coachID));
                            }}>Book More Sessions</Button>
                            :
                            <Button className="buttonSecondary" onClick={() => {
                              history.push('/calendar');
                            }}>Schedule Sessions</Button>
                          }
                          {coaches[coachID]['actionsAvailable'].includes('review') ?
                            <div>
                              <Button className="buttonSecondary" onClick={() => setShowPromptForReview(coachID)}>Write Review</Button>
                            </div> : null}


                        </TableCell>
                      </TableRow>
                    );
                  }) : null}
                </TableBody>
              </Table>
            </TableContainer>
            <div className='mobileBookingsTable'>
              {coaches ? Object.keys(coaches).map((coachID, i) => {
                const sessionFormattedDate = completedSessions[coachID] && completedSessions[coachID].length > 0 ? new Date(completedSessions[coachID][0]['startTime']).toLocaleDateString() : 'N/A';
                return (
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className='heading'>Coach Name</TableCell>
                          <TableCell><span className="link" onClick={() => {
                            history.push('/coach-profile/' + (coaches[coachID].coachInfo.customUrl ? coaches[coachID].coachInfo.customUrl : coachID));
                          }}>{coaches[coachID]['coachInfo']['firstName'] + ' ' + coaches[coachID]['coachInfo']['lastName']}</span></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Last Session</TableCell>
                          <TableCell align="right">{sessionFormattedDate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Total Sessions</TableCell>
                          <TableCell align="right">{coaches[coachID]['connection']['numSessions']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Completed</TableCell>
                          <TableCell align="right">{coaches[coachID]['connection']['sessionsCompleted']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Upcoming</TableCell>
                          <TableCell align="right">{coaches[coachID]['connection']['acceptedSessions']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Pending</TableCell>
                          <TableCell align="right">{coaches[coachID]['connection']['pendingSessions']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Need to Schedule</TableCell>
                          <TableCell align="right">{coaches[coachID]['connection']['sessionsLeft']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Action</TableCell>
                          <TableCell align="right" className="athleteCoachActions">
                            {coaches[coachID]['connection']['sessionsLeft'] === 0 ?
                              <Button className="buttonPrimary" onClick={() => {
                                history.push('/book-session/' + (coaches[coachID].coachInfo.customUrl ? coaches[coachID].coachInfo.customUrl : coachID));
                              }}>Book More Sessions</Button>
                              :
                              <Button className="buttonSecondary" onClick={() => {
                                history.push('/calendar');
                              }}>Schedule Sessions</Button>
                            }
                            {coaches[coachID]['actionsAvailable'].includes('review') ?
                              <div>
                                <Button className="buttonSecondary" onClick={() => setShowPromptForReview(coachID)}>Write Review</Button>
                              </div> : null}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              }) : null}

            </div>
          </div>
          <div className="lookingForNew">
            <p>Looking for a New Private Coach in Your Area?</p>
            <SearchForCoaches />
          </div>
        </div> : null}
      <Dialog onClose={() => setShowPromptForReview(null)} open={promptForReview}>
        <ReviewForm coachId={promptForReview} onComplete={() => window.location.reload(false)} />
      </Dialog>
    </div>
  );
};

export default AthleteCoaches;