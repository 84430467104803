import React from "react";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import Actions from "../Actions/Actions";
import AthleteCoaches from "../AthleteCoaches/AthleteCoaches.js";
import AthleteDashboard from "../AthleteDashboard/AthleteDashboard.js";
import AthleteVideos from "../AthleteVideos/AthleteVideos";
import BookSessionPage from "../BookSessionPage/BookSessionPage";
import CoachAccount from "../CoachAccount/CoachAccount";
import CoachCalendar from "../CoachCalendar/CoachCalendar";
import CoachInbox from "../CoachInbox/CoachInbox";
import CoachList from "../CoachList/CoachList";
import CoachProfilePage from "../CoachProfilePage/CoachProfilePage";
import Join from "../Join/Join";
import Landing from "../Landing/Landing";
import MessageCoachPage from "../MessageCoachPage/MessageCoachPage";
import MobileHeader from "../MobileHeader/MobileHeader";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import SignOut from "../SignOut/SignOut.js";
import SiteTerms from "../SiteTerms/SiteTerms";
import "./AthleteNav.css";

export default function AthleteNav(props) {
  return (
    <Router>
      <MobileHeader>
        <li>
          <NavLink activeClassName="" to="/dashboard" className="athleteNavTab">
            Dashboard
          </NavLink>
        </li>
        {/* <li>
                        <Link to="/profile" className="athleteNavTab">Profile</Link>
                    </li> */}
        <li>
          <NavLink
            activeClassName="athleteNavTabActive"
            to="/inbox"
            className="athleteNavTab"
          >
            Inbox
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="athleteNavTabActive"
            to="/athletecoaches"
            className="athleteNavTab"
          >
            Bookings
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="athleteNavTabActive"
            to="/calendar"
            className="athleteNavTab"
          >
            Calendar
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="athleteNavTabActive"
            to="/account"
            className="athleteNavTab"
          >
            Account
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="coachNavTabFilledGreenActive"
            to="/videos"
            className="coachNavTabFilledGreen"
          >
            Videos
          </NavLink>
        </li>
        <li>
          <Link to="/signout" className="athleteNavTab">
            Sign Out
          </Link>
        </li>
      </MobileHeader>
      <div className="athleteNavContainerDiv">
        <p className="athleteNavGreeting"></p>
        <ul className="athleteNavListOfTabs">
          <li>
            <NavLink
              activeClassName="athleteNavTabActive"
              to="/dashboard"
              className="athleteNavTab"
            >
              Dashboard
            </NavLink>
          </li>
          {/* <li>
                        <Link to="/profile" className="athleteNavTab">Profile</Link>
                    </li> */}
          <li>
            <NavLink
              activeClassName="athleteNavTabActive"
              to="/inbox"
              className="athleteNavTab"
            >
              Inbox
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="athleteNavTabActive"
              to="/athletecoaches"
              className="athleteNavTab"
            >
              Bookings
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="athleteNavTabActive"
              to="/calendar"
              className="athleteNavTab"
            >
              Calendar
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="athleteNavTabActive"
              to="/account"
              className="athleteNavTab"
            >
              Account
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="coachNavTabFilledGreenActive"
              to="/videos"
              className="coachNavTabFilledGreen"
            >
              Videos
            </NavLink>
          </li>
        </ul>
        <Link to="/signout" className="athleteNavTab">
          Sign Out
        </Link>
      </div>
      <Switch>
        <Route
          path={["/dashboard"]}
          exact
          render={(props) => <AthleteDashboard {...props} />}
        />
        <Route
          path="/videos"
          exact
          render={(props) => <AthleteVideos {...props} />}
        />
        <Route
          path="/signout"
          exact
          render={(props) => <SignOut {...props} />}
        />
        <Route
          path="/athletecoaches"
          exact
          render={(props) => <AthleteCoaches {...props} />}
        />

        <Route
          path="/coaches"
          exact
          render={(props) => <CoachList {...props} />}
        />
        <Route
          path="/account"
          exact
          render={(props) => <CoachAccount {...props} />}
        />
        <Route
          path="/calendar"
          exact
          render={(props) => <CoachCalendar {...props} />}
        />
        <Route
          path="/inbox"
          exact
          render={(props) => <CoachInbox {...props} />}
        />
        <Route
          path="/book-session/:id"
          exact
          render={(props) => <BookSessionPage {...props} />}
        />
        <Route
          path="/message-coach/:id"
          exact
          render={(props) => <MessageCoachPage {...props} />}
        />
        <Route
          path="/coach-profile/:id"
          exact
          render={(props) => <CoachProfilePage {...props} />}
        />
        <Route
          path="/privacy-policy"
          exact
          render={(props) => <PrivacyPolicy {...props} />}
        />
        <Route
          path="/site-terms"
          exact
          render={(props) => <SiteTerms {...props} />}
        />

        <Route path="/" exact render={(props) => <Landing {...props} />} />
        <Route
          path="/action"
          exact
          render={(props) => <Actions {...props} />}
        />
        <Route path="/join" exact render={(props) => <Join {...props} />} />
        {/* Catch all for paths that don't match one of our routes  */}
        <Redirect to="/dashboard" />
      </Switch>
    </Router>
  );
}
