import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { CheckBox, LocationOn, Message } from "@material-ui/icons";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PinDropIcon from "@material-ui/icons/PinDrop";
import SportsIcon from "@material-ui/icons/Sports";
import StarIcon from "@material-ui/icons/Star";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import React from "react";
import Colors from "../../constants/colors";
import "./WhyThreeBoxPanel.scss";

const WhyThreeBoxPanel = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      textAlign: "center",
    },
  }));

  let box1icon = (
    <PinDropIcon style={{ color: Colors.secondary }} color="other" />
  );
  let box2icon = <StarIcon style={{ color: Colors.secondary }} />;
  let box3icon = <TrendingUpIcon style={{ color: Colors.secondary }} />;
  if (props.page === "DedicatedCoaches") {
    box1icon = <MonetizationOnIcon style={{ color: Colors.secondary }} />;
    box2icon = <SportsIcon style={{ color: Colors.secondary }} />;
    box3icon = <PhoneIphoneIcon style={{ color: Colors.secondary }} />;
  }
  if (props.page === "homeLeft" || props.page === "ApplyToCoach") {
    box1icon = <MonetizationOnIcon style={{ color: Colors.secondary }} />;
    box2icon = <Message style={{ color: Colors.secondary }} />;
    box3icon = <PhoneIphoneIcon style={{ color: Colors.secondary }} />;
  }
  if (props.page === "homeRight") {
    box1icon = <LocationOn style={{ color: Colors.secondary }} />;
    box2icon = <StarIcon style={{ color: Colors.secondary }} />;
    box3icon = <CheckBox style={{ color: Colors.secondary }} />;
  }

  const classes = useStyles();
  return (
    <div id="why-three-box-panel">
      <div className="ThreeBoxTitle">
        <h2>{props.title}</h2>
      </div>
      <div className="ThreeBoxPanels">
        <div style={{ display: "inline-block" }}>
          <div className={classes.root}>
            <Paper elevation={0} className="panel">
              <p className="HIWHead"> {props.box1.header}</p>
              {box1icon}
              <p className="HIWCapt">{props.box1.caption}</p>
            </Paper>
            <Paper elevation={0} className="panel">
              <p className="HIWHead"> {props.box2.header}</p>
              {box2icon}
              <p className="HIWCapt">{props.box2.caption}</p>
            </Paper>
            <Paper elevation={0} className="panel">
              <p className="HIWHead"> {props.box3.header}</p>
              {box3icon}
              <p className="HIWCapt"> {props.box3.caption}</p>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyThreeBoxPanel;
