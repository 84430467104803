import React, { useState } from "react";
import {
  NavLink,
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import OurStory from "../OurStory/OurStory.js";
// import Landing from '../Landing/Landing';
import AthletesAndParents from "../AthletesAndParents/AthletesAndParents";
import CoachList from "../CoachList/CoachList";
import DedicatedCoaches from "../DedicatedCoaches/DedicatedCoaches";
import Join from "../Join/Join";
import Login from "../Login/Login";
// import logo from './Athletes-Untapped-Logo-Rectangle.png';
import { Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Actions from "../Actions/Actions.js";
import ApplyToCoach from "../ApplyToCoach/ApplyToCoach";
import BookSessionPage from "../BookSessionPage/BookSessionPage";
import CategoryZipList from "../CategoryZipList/CategoryZipList.js";
import CoachAgreement from "../CoachAgreement/CoachAgreement";
import CoachProfilePage from "../CoachProfilePage/CoachProfilePage";
import Contact from "../Contact/Contact.js";
import FAQs from "../FAQs/FAQs.js";
import ForgotPassword from "../ForgotPassword/ForgotPassword.js";
import Landing from "../Landing/Landing";
import MessageCoachPage from "../MessageCoachPage/MessageCoachPage";
import MobileHeader from "../MobileHeader/MobileHeader";
import OurTeam from "../OurTeam/OurTeam.js";
import Partnerships from "../Partnerships/Partnerships";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy.js";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Signup from "../Signup/Signup.js";
import SiteTerms from "../SiteTerms/SiteTerms";
import logo from "./AULogo.png";
import "./HomeNav.scss";

export default function HomeNav(props) {
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const openLoginHandler = () => {
    setOpenLogin(true);
  };

  const closeLoginHandler = () => {
    setOpenLogin(false);
  };

  const openSignupHandler = () => {
    setOpenSignup(true);
  };

  const closeSignupHandler = () => {
    setOpenSignup(false);
  };

  const openForgotPasswordHandler = () => {
    setShowForgotPassword(true);
  };

  const closeForgotPasswordHandler = () => {
    setShowForgotPassword(false);
  };

  function getMenuItems() {
    return (
      <>
        <li>
          <NavLink
            to="/parents-and-athletes"
            activeClassName="homeNavTabActive"
            className="homeNavTab"
          >
            Parents &amp; Athletes
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/apply-to-coach"
            activeClassName="homeNavTabActive"
            className="homeNavTab"
          >
            Apply to Coach
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/our-difference"
            activeClassName="homeNavTabActive"
            className="homeNavTab"
          >
            Our Difference
          </NavLink>
        </li>
        <li>
          <a
            href="https://athletes-untapped.myshopify.com/"
            activeClassName="homeNavTabActive"
            className="homeNavTab"
          >
            Store
          </a>
        </li>
        <li>
          <Link onClick={openLoginHandler} className="loginLink">
            Login
          </Link>
          <Dialog
            onClose={closeLoginHandler}
            open={openLogin}
            fullWidth="true"
            maxWidth="sm"
          >
            <DialogContent dividers>
              <Login
                showReset={() => {
                  closeLoginHandler();
                  closeSignupHandler();
                  openForgotPasswordHandler();
                }}
                switch={() => {
                  closeLoginHandler();
                  closeForgotPasswordHandler();
                  openSignupHandler();
                }}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            onClose={closeForgotPasswordHandler}
            open={showForgotPassword}
            fullWidth="true"
            maxWidth="sm"
          >
            <DialogContent dividers>
              <ForgotPassword closeReset={closeForgotPasswordHandler} />
            </DialogContent>
          </Dialog>
        </li>
        <li>
          <Button
            onClick={openSignupHandler}
            className="buttonSecondary signupButton"
          >
            Sign Up
          </Button>
          <Dialog
            onClose={closeSignupHandler}
            open={openSignup}
            fullWidth="true"
            maxWidth="sm"
          >
            <DialogContent dividers>
              <Signup
                switch={() => {
                  closeSignupHandler();
                  closeForgotPasswordHandler();
                  openLoginHandler();
                }}
                close={closeSignupHandler}
              />
            </DialogContent>
          </Dialog>
        </li>
      </>
    );
  }
  return (
    <Router>
      {window.location.pathname !== "/coach-agreement" ? (
        <div>
          <MobileHeader>{getMenuItems()}</MobileHeader>
          <header className="homeNavHeader">
            <div className="navInner">
              <NavLink
                title={
                  "Book a 1on1 training lesson with an Athletes Untapped private sports coach to help build your confidence and individual skills."
                }
                className="logo"
                to="/"
              >
                <img
                  alt={
                    "Book a 1on1 training lesson with an Athletes Untapped private sports coach to help build your confidence and individual skills."
                  }
                  src={logo}
                  className="AULogo"
                />
              </NavLink>
              <nav>
                <ul className="homeNavBar">{getMenuItems()}</ul>
              </nav>
            </div>
          </header>
        </div>
      ) : null}
      <ScrollToTop />
      <Switch>
        <Route path="/" exact render={(props) => <Landing {...props} />} />
        <Route
          path="/our-difference"
          exact
          render={(props) => <OurStory {...props} />}
        />
        <Route path="/login" exact render={(props) => <Login {...props} />} />

        <Route path="/join" exact render={(props) => <Join {...props} />} />
        <Route
          path="/coaches"
          exact
          render={(props) => <CoachList {...props} />}
        />
        <Route
          path="/dedicatedcoaches"
          exact
          render={(props) => <DedicatedCoaches {...props} />}
        />
        <Route
          path="/parents-and-athletes"
          exact
          render={(props) => <AthletesAndParents {...props} />}
        />
        <Route
          path="/apply-to-coach"
          exact
          render={(props) => <ApplyToCoach {...props} />}
        />
        <Route
          path="/ourteam"
          exact
          render={(props) => <OurTeam {...props} />}
        />
        <Route
          path="/contact"
          exact
          render={(props) => <Contact {...props} />}
        />
        <Route path="/faqs" exact render={(props) => <FAQs {...props} />} />
        <Route
          path="/action"
          exact
          render={(props) => <Actions {...props} />}
        />
        <Route
          path="/privacy-policy"
          exact
          render={(props) => <PrivacyPolicy {...props} />}
        />
        <Route
          path="/site-terms"
          exact
          render={(props) => <SiteTerms {...props} />}
        />
        <Route
          path="/coach-agreement"
          exact
          render={(props) => <CoachAgreement {...props} />}
        />
        <Route
          path="/coach-profile/:id"
          exact
          render={(props) => <CoachProfilePage {...props} />}
        />
        <Route
          path="/partnerships"
          exact
          render={(props) => <Partnerships {...props} />}
        />
        <Route
          path="/book-session/:id"
          exact
          render={(props) => <BookSessionPage {...props} />}
        />
        <Route
          path="/message-coach/:id"
          exact
          render={(props) => <MessageCoachPage {...props} />}
        />
        <Route
          path="/coaches-by-zip"
          exact
          render={(props) => <CategoryZipList {...props} />}
        />
        {/* Catch all for paths that don't match one of our routes  */}
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}
