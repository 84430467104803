// @ts-nocheck
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { Check, Info } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getConnectedAccount, getIncome, updateProfile } from "../../api/api";
import Colors from "../../constants/colors";
import { clientID, oauth_redirect_uri } from "../../stripe_api_key.json";
import { AuthContext } from "../App/App";
import CustomPricingPackageDialog from "../CustomPricingPackageDialog/CustomPricingPackageDialog";
import "./CoachPayments.scss";

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

const CoachPayments = (props) => {
  const { user } = useContext(AuthContext);
  const url = `https://connect.stripe.com/express/oauth/authorize?client_id=${clientID}&redirect_uri=${oauth_redirect_uri}&state=${user.firebaseUser.uid}&suggested_capabilities[]=transfers&stripe_user[email]=${user.profile.email}`;
  const [AUFee] = useState(0.2);
  const [sessionRate, setSessionRate] = useState(user.profile.sessionRate);
  const [additionalAthletesRate, setAdditionalAthletesRate] = useState(
    user.profile.additionalAthletesRate || 0
  );
  const [stripeAcc, setStripeAcc] = useState(null);
  const [stripeBalance, setStripeBalance] = useState(null);
  const [stripeLoginLinks, setStripeLoginLinks] = useState(null);
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [openSnackbarMessage, setOpenSnackBarMessage] = useState(null);
  const [totalIncome, setTotalIncome] = useState(null);
  const [selectedYearIncome, setSelectedYearIncome] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [incomeYears, setIncomeYears] = useState([]);

  useEffect(() => {
    if (user.profile.stripeID) {
      user.firebaseUser.getIdToken().then(function (idToken) {
        getConnectedAccount(idToken, user.firebaseUser.uid)
          .then((response) => {
            setStripeAcc(response["stripeAccount"]);
            setStripeBalance(response["balance"]);
            setStripeLoginLinks(response["loginLink"]);
          })
          .catch((e) => console.log(e));
      });
    }
  }, [user.firebaseUser, user.profile.stripeID]);

  useEffect(() => {
    user.firebaseUser.getIdToken().then(function (idToken) {
      getIncome(idToken, user.firebaseUser.uid)
        .then((response) => {
          setTotalIncome(response.income);
        })
        .catch((e) => console.log(e));
    });
  }, [user, user.firebaseUser]);

  useEffect(() => {
    setIncomeYears(() => {
      const endYear = new Date().getFullYear();
      const startYear = new Date(
        user.firebaseUser.metadata.creationTime
      ).getFullYear();
      let options = [];
      for (let i = startYear; i <= endYear; i++) {
        options.push({ value: i, label: i });
      }
      return options;
    });
  }, [user, user.firebaseUser]);

  const loadYearIncome = useCallback(
    (year) => {
      setSelectedYearIncome(null);
      user.firebaseUser.getIdToken().then(function (idToken) {
        getIncome(idToken, user.firebaseUser.uid, year)
          .then((response) => {
            setSelectedYearIncome(response.income);
          })
          .catch((e) => console.log(e));
      });
    },
    [user.firebaseUser]
  );

  useEffect(() => {
    loadYearIncome(selectedYear);
  }, [loadYearIncome, selectedYear]);

  const saveSessionRate = (e) => {
    e.preventDefault();

    if (!sessionRate || sessionRate > 300 || sessionRate < 30) {
      setOpenSnackBarMessage("Session rate must be between 30 and 300.");
      setOpenSnackBar(true);
      return;
    }

    if (additionalAthletesRate > 300 || additionalAthletesRate < 0) {
      setOpenSnackBarMessage(
        "Additional athlete rate must be between 0 and 300."
      );
      setOpenSnackBar(true);
      return;
    }

    const vals = {
      sessionRate,
      additionalAthletesRate,
    };

    user.firebaseUser.getIdToken().then(function (idToken) {
      updateProfile(idToken, vals, user.firebaseUser.uid)
        .then((_) => {
          window.location.reload(false);
        })
        .catch((e) => console.log(e));
    });
  };

  const onYearChange = (event) => {
    setSelectedYear(event.target.value);
    loadYearIncome(event.target.value);
  };

  const checkIcon = <Check style={{ color: Colors.primary }} />;

  return (
    <div>
      {user.profile.stripeID && stripeAcc != null ? (
        <div className="coachPaymentsHeader">
          <h1>
            Total Earnings:{" "}
            <span className="color-secondary">
              {totalIncome !== null ? (
                "$" + totalIncome.toFixed(2)
              ) : (
                <CircularProgress />
              )}
            </span>
          </h1>
          <div className="annualIncomeContainer">
            <h2>Annual Income</h2>
            <TextField
              name="incomeYear"
              value={selectedYear}
              variant="outlined"
              select
              label="Year"
              onChange={onYearChange}
            >
              {incomeYears.map(({ value, label }, i) => (
                <MenuItem key={i} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <h2 className="color-secondary">
              {selectedYearIncome !== null ? (
                "$" + selectedYearIncome.toFixed(2)
              ) : (
                <CircularProgress />
              )}
            </h2>
          </div>
        </div>
      ) : null}
      <div className="coachPaymentsPricingWrapper">
        <div className="coachPaymentsPricingInnerWrapper">
          <h3>Pricing</h3>
          <div className="CoachPaymentsSessionRateContainerLower">
            <div className="CoachPaymentsSessionRateCustomize">
              <h6 className="color-secondary">Set your session rate</h6>
              <div className="CoachPaymentsSessionRateCustomizeDiv">
                <div className="CoachPaymentsInput">
                  <div className="dollar-input">
                    $
                    <input
                      className="SessionRateInput"
                      name="typeOfEvent"
                      type="number"
                      min={30}
                      max={300}
                      value={sessionRate}
                      onChange={(e) => setSessionRate(e.target.value)}
                    ></input>
                  </div>
                  <span>Session Rate</span>
                </div>
                <div className="CoachPaymentsInput">
                  <div className="dollar-input">
                    $
                    <input
                      className="SessionRateInput"
                      name="typeOfEvent"
                      type="number"
                      min={0}
                      max={300}
                      value={additionalAthletesRate}
                      onChange={(e) =>
                        setAdditionalAthletesRate(e.target.value)
                      }
                    ></input>
                  </div>
                  <div className="input-label">
                    <span>Per Additional Athlete</span>
                    <Tooltip title="AU coaches can set a cost per additional athlete. This way, when clients want to bring multiple athletes to each session, you will be compensated appropriately. It's not required, but highly recommended!">
                      <Info className="toolTipIcon" />
                    </Tooltip>
                  </div>
                </div>
                <div className="pricingRow">
                  <div className="pricingLeft color-secondary">
                    {AUFee * 100}%
                  </div>
                  <div className="pricingRight">
                    AU Fee
                    <Tooltip title="AU takes a 20% cut of every training session booked through our platform. We see you as part of the AU family - we work around the clock to find you clients and host the technology, so all you need to focus on is training. ">
                      <Info className="toolTipIcon" />
                    </Tooltip>
                  </div>
                </div>
                <div className="pricingRow">
                  <div className="pricingLeft color-secondary earnings">
                    ${((1 - AUFee) * sessionRate).toFixed(2)}
                  </div>
                  <div className="pricingRight">Earnings/Session</div>
                </div>
                <div style={{ width: "100%" }}>
                  <Button
                    className="buttonPrimary"
                    onClick={(e) => saveSessionRate(e)}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <h6 className="color-secondary">What to keep in mind</h6>
              <ul className="coachPaymentNotes">
                <li>
                  {checkIcon}The minimum session rate you can offer is $30.
                </li>
                <li>{checkIcon}The average AU session rate is $65.</li>
                <li>
                  {checkIcon}You can change your session rate at any time.
                </li>
                <li>
                  {checkIcon}This is your business! Use our platform to grow it.
                </li>
              </ul>
            </div>
          </div>
          <CustomPricingPackageDialog />
        </div>
      </div>
      {/* <div className="CoachPaymentsHeader">
                <img src={blank_avatar} alt="Blank Avatar" className="coachProfileHeaderAvatar"></img>
                <div className="CoachPaymentsHeaderDynamicInfo">
                    <p>{user.profile.firstName} {user.profile.lastName}</p>
                    <p>Zip Code: {user.profile.zipCode}</p>
                    <p>Private {user.profile.sport.slice(0,1).toUpperCase()+user.profile.sport.slice(1)} Coach</p>   
                </div>
                <div className="CoachPaymentsHeaderDynamicInfo">
                    <p>Total Income: $7,000</p>
                </div>
            </div> */}
      <div className="CoachPaymentsMainSection">
        <h5>Payment Details</h5>
        {!user.profile.stripeID || stripeAcc == null ? (
          <Button
            className="buttonPrimary"
            onClick={() => (window.location = url)}
          >
            Set Up Payments
          </Button>
        ) : (
          <div className="CoachPaymentsLoadedSection">
            {stripeBalance && stripeBalance["available"] ? (
              <div>
                <div className="CoachPaymentsLoadedSectionBalance">
                  <div>
                    <p>Your Balance </p>
                    <p style={{ textAlign: "center", color: "darkgreen" }}>
                      ${" "}
                      {(stripeBalance["available"][0]["amount"] / 100).toFixed(
                        2
                      )}
                    </p>
                  </div>
                  <div>
                    <p>Pending </p>
                    <p style={{ textAlign: "center", color: "darkgreen" }}>
                      ${" "}
                      {(stripeBalance["pending"][0]["amount"] / 100).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            <Button
              className="buttonPrimary"
              onClick={() => (window.location = stripeLoginLinks["url"])}
            >
              Edit Payment Info
            </Button>
            <ul>
              <li>
                In order to get paid once you complete a session, log onto your
                AU account and go to your calendar. Each of your sessions will
                have a 'Complete' button assigned to them. By clicking this
                button, you will trigger the payment to be sent to your Stripe
                account.
              </li>
              <li>
                Once you complete a session, the payment will hit your bank
                account/debit card in 2 business days.
              </li>
            </ul>
          </div>
        )}
      </div>

      <Snackbar open={openSnackbar} onClose={(e) => setOpenSnackBar(false)}>
        <Alert onClose={(e) => setOpenSnackBar(false)} severity="error">
          {openSnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CoachPayments;
