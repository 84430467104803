import React, {useContext, useState} from 'react';
import {ThemeProvider, withStyles} from '@material-ui/core/styles';
import {AuthContext} from "../App/App";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from 'react-router-dom';


const DialogTitle = withStyles()((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton style={{width: '.5rem', height: '.5rem', position: 'absolute', top: '20px', right: '20px'}}
                            aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon style={{width: '.8rem', height: '.8rem'}}/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const CoachCalenderDialog = props => {
    const history = useHistory();
    const {user} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [timeUpdates, setTimeUpdates] = useState({});
    const [editStartTime, setEditStartTime] = useState(new Date(props.selectedEvent['startTime']));
    const [editEndTime, setEditEndTime] = useState(new Date(props.selectedEvent['endTime']));


    const handleClose = () => {
        props.setOpen(false);
    };

    const updateEventHandler = (e, eventID, newStatus) => {
        e.preventDefault();
        setLoading(true);
        let updates;
        let updater;
        if (newStatus === 'personalDeleted') {
            updates = {
                'status': 'personal',
                'deleted': true,
                'userID': user.firebaseUser.uid
            };
            props.for === "coach" ? updater = "coach" : updater = "athlete";
        } else if (props.for === "coach") {
            updates = {
                'coachStatus': newStatus
            };
            updater = "coach";
        } else {
            updates = {
                'athleteStatus': newStatus
            };
            updater = "athlete";
        }
        props.updateEvent(eventID, updates, updater);
    };

    const completeEventHandler = (e, eventID, newStatus) => {
        e.preventDefault();
        let updates;
        let completer;
        setLoading(true);
        if (props.for === "coach") {
            updates = {
                'coachStatus': newStatus
            };
            completer = "coach";
        } else {
            updates = {
                'athleteStatus': newStatus
            };
            completer = "athlete";
        }
        props.completeEvent(eventID, updates, completer);
    };

    const updateEventTimeHandler = (e, eventID, personal) => {
        setLoading(true);
        e.preventDefault();
        let updates;
        let updater;
        console.log('here', props.selectedEvent);
        if (personal) {
            updates = {
                ...timeUpdates,
                'status': 'personal',
                'userID': user.firebaseUser.uid
            };
            props.for === "coach" ? updater = "coach" : updater = "athlete";
        } else {
            updates = {
                ...timeUpdates,
                'coachStatus': props.selectedEvent.coachStatus,
                'athleteStatus': props.selectedEvent.athleteStatus,
                'status': props.selectedEvent.status
            };
            if (props.for === "coach") {
                // updates = {
                //   ...timeUpdates,
                //   'coachStatus': 'accepted',
                //   'athleteStatus': 'pending',
                //   'status': 'pending'
                // };
                updater = "coach";
            } else {
                // updates = {
                //   ...timeUpdates,
                //   'coachStatus': 'pending',
                //   'athleteStatus': 'accepted',
                //   'status': 'pending'
                // };
                updater = "athlete";

            }
            console.log('updates', updates);
        }

        props.updateEvent(eventID, updates, updater);

    };

    const [confirmCallback, setConfirmCallback] = useState(null)

    return (
        <div>
            <Dialog open={confirmCallback != null}>
                <DialogTitle>Are you sure?</DialogTitle>
                <Button style={{margin: '10px'}} onClick={() => setConfirmCallback(null)} className={'buttonWarn'}>Cancel</Button>
                <Button style={{margin: '0 10px 10px 10px'}} onClick={(e) => {updateEventHandler(e, props.selectedEvent['eventDocID'], confirmCallback); setConfirmCallback(null)}} className={'buttonPrimary'}>Yes</Button>
            </Dialog>
            <Dialog className="calendarEvent" onClose={handleClose} aria-labelledby="customized-dialog-title"
                    open={props.open}>
                {loading ? <div style={{padding: '20px'}}><CircularProgress/></div> :
                    <div>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose} fullwidth>
                            {props.selectedEvent['user'] ? "Personal Event" + (props.selectedEvent['title'] ? ': ' + props.selectedEvent['title'] : "") : "Session with " + props.name}
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                                {props.selectedEvent['coachStatus'] === "accepted" && props.selectedEvent['athleteStatus'] === "pending" ?
                                    <div>
                                        {props.for === "coach" ?
                                            <div>
                                                <Alert severity="warning">This event is pending
                                                    until {props.name} accepts.</Alert>
                                                <Alert severity="success">You have accepted this event.</Alert>
                                            </div> :
                                            <div>
                                                <Alert severity="warning">Accepting will officially book this event.
                                                    However, editing will instead require {props.name} to accept the
                                                    changed
                                                    time.</Alert>
                                                <Alert severity="success">{props.name} has accepted this event.</Alert>
                                            </div>}
                                    </div>
                                    : null}
                                {props.selectedEvent['coachStatus'] === "pending" && props.selectedEvent['athleteStatus'] === "accepted" ?

                                    <div>
                                        {props.for === "coach" ?
                                            <div>
                                                <Alert severity="warning">Accepting will officially book this event.
                                                    However, editing will instead require {props.name} to accept the
                                                    changed
                                                    time.</Alert>
                                                <Alert severity="success">{props.name} has accepted this event.</Alert>
                                            </div> :
                                            <div>
                                                <Alert severity="warning">This event is pending
                                                    until {props.name} accepts.</Alert>
                                                <Alert severity="success"> You have accepted this event.</Alert>
                                            </div>}
                                    </div>
                                    : null}

                                {props.selectedEvent['coachStatus'] === "accepted" && props.selectedEvent['athleteStatus'] === "accepted" ?
                                    <div>
                                        <Alert severity="success">You have accepted this event.</Alert>
                                        <Alert severity="success">{props.name} has accepted this event.</Alert>
                                    </div>
                                    : null}
                                {props.selectedEvent['status'] && props.selectedEvent['status'] === "completed" ?

                                    <div>
                                        {props.for === "coach" ?
                                            <div>
                                                <Alert severity="info">This is a completed session! Click below to view
                                                    your
                                                    payments or give video feedback!</Alert>
                                            </div> :
                                            <div>
                                                <Alert severity="info">This is a completed session! Click below to view
                                                    video feedback!</Alert>
                                            </div>}

                                    </div>
                                    : null}
                                {props.selectedEvent['user'] && props.selectedEvent['status'] === 'personal' ?
                                    <div>
                                        <Alert severity="info">This is a personal event! Edit or delete it as you see
                                            fit.</Alert>
                                    </div> : null}
                            </Typography>
                            <Typography gutterBottom style={{fontSize: 'large'}}>
                                {!editMode ? new Date(props.selectedEvent['startTime']).toDateString() :
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <ThemeProvider>
                                            <DatePicker
                                                disableToolbar
                                                variant="inline"
                                                input
                                                value={editStartTime}
                                                onChange={date => {
                                                    let newDate = editStartTime;
                                                    newDate.setMonth(date.getMonth());
                                                    newDate.setDate(date.getDate());
                                                    newDate.setFullYear(date.getFullYear());
                                                    newDate.setSeconds(0);
                                                    newDate.setMilliseconds(0);
                                                    setEditStartTime(newDate);
                                                    setEditEndTime(new Date(newDate.valueOf() + 3600000));
                                                    setTimeUpdates({
                                                        ...timeUpdates,
                                                        'startTime': newDate.valueOf(),
                                                        'endTime': newDate.valueOf() + 3600000
                                                    });
                                                }}
                                            />
                                        </ThemeProvider>
                                    </MuiPickersUtilsProvider>}
                            </Typography>
                            <Typography gutterBottom>
                                Start: {!editMode ? new Date(props.selectedEvent['startTime']).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }) :
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <ThemeProvider>
                                        <TimePicker
                                            variant="inline"
                                            value={editStartTime}
                                            input
                                            minutesStep={15}
                                            onChange={date => {
                                                console.log(props.selectedEvent);
                                                let newDate = editStartTime;
                                                newDate.setHours(date.getHours());
                                                newDate.setMinutes(date.getMinutes());
                                                newDate.setSeconds(0);
                                                newDate.setMilliseconds(0);
                                                setEditStartTime(newDate);
                                                setEditEndTime(new Date(newDate.valueOf() + 3600000));
                                                setTimeUpdates({
                                                    ...timeUpdates,
                                                    'startTime': newDate.valueOf(),
                                                    'endTime': newDate.valueOf() + 3600000
                                                });
                                            }}
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>}
                            </Typography>
                            <Typography gutterBottom>
                                End: {!editMode ? new Date(props.selectedEvent['endTime']).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }) :
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <ThemeProvider>
                                        <TimePicker
                                            variant="inline"
                                            input
                                            minutesStep={15}
                                            disabled={props.selectedEvent['user'] ? false : true}
                                            value={editEndTime}
                                            onChange={date => {

                                                date.setSeconds(0);
                                                date.setMilliseconds(0);
                                                setEditEndTime(date);
                                                setTimeUpdates({
                                                    ...timeUpdates,
                                                    'endTime': date.valueOf()
                                                });
                                            }}
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>}
                            </Typography>

                        </DialogContent>
                        {editMode ?
                            <DialogActions>
                                <Button
                                    className="buttonPrimary"
                                    onClick={(e) => updateEventTimeHandler(e, props.selectedEvent['eventDocID'], props.selectedEvent['user'] ? true : false)}>
                                    Save </Button>
                            </DialogActions> :
                            <DialogActions>
                                {props.selectedEvent['status'] && props.selectedEvent['status'] === "completed" ?
                                    <div>
                                        {props.for === "coach" ?
                                            <Button onClick={() => history.push('/payments')}
                                                    className="buttonSecondary">
                                                Payments
                                            </Button> : null}
                                        <Button onClick={() => history.push('/videos')} className="buttonPrimary">
                                            Videos
                                        </Button>
                                    </div>
                                    : null}
                                {props.selectedEvent['user'] && props.selectedEvent['status'] === "personal" ?
                                    <div>
                                        <Button
                                            onClick={(ev) => setConfirmCallback(setConfirmCallback("personalDeleted"))}
                                            className="buttonSecondary">
                                            Delete
                                        </Button>
                                        <Button className="buttonPrimary" onClick={e => setEditMode(true)}>
                                            Edit
                                        </Button>
                                    </div>
                                    : null}
                                {props.selectedEvent['status'] && props.selectedEvent['status'] === "pending" ?
                                    <div>
                                        {(props.for === "coach" && props.selectedEvent['coachStatus'] === "accepted") ||
                                        (props.for === "athlete" && props.selectedEvent['athleteStatus'] === "accepted") ?
                                            <div>
                                                <Button
                                                    onClick={(ev) => {setConfirmCallback("denied")}}
                                                    className="buttonSecondary">
                                                    Delete
                                                </Button>
                                                <Button className="buttonPrimary" onClick={e => setEditMode(true)}>
                                                    Edit
                                                </Button>
                                            </div> :
                                            <div>
                                                <Button className="buttonSecondary" onClick={e => setEditMode(true)}>
                                                    Edit
                                                </Button>
                                                <Button
                                                    onClick={(e) => updateEventHandler(e, props.selectedEvent['eventDocID'], "denied")}
                                                    className="buttonSecondary">
                                                    Deny
                                                </Button>
                                                <Button
                                                    onClick={(e) => updateEventHandler(e, props.selectedEvent['eventDocID'], "accepted")}
                                                    className="buttonPrimary">
                                                    Accept
                                                </Button>
                                            </div>
                                        }

                                    </div>
                                    :
                                    <div>
                                        {props.selectedEvent['status'] && props.selectedEvent['status'] === "accepted" ?
                                            <div>
                                                {props.for === "coach" ?
                                                    <Button className="buttonPrimary"
                                                            onClick={(e) => completeEventHandler(e, props.selectedEvent['eventDocID'], "completed")}>
                                                        Complete
                                                    </Button> : null}
                                                <Button className="buttonPrimary" onClick={e => setEditMode(true)}>
                                                    Edit
                                                </Button>
                                                <Button
                                                    onClick={(ev) => {setConfirmCallback("canceled")}}
                                                    className="buttonSecondary">
                                                    Cancel
                                                </Button>
                                            </div>
                                            : null}
                                    </div>}
                            </DialogActions>}
                    </div>
                }
            </Dialog>
        </div>
    );
};

export default CoachCalenderDialog;