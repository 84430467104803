import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
// import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import "./ReadyToTrainFormWide.scss";
import GeocodingUtils from "../../utils/GeocodingUtils";

//mycode
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { sports } from "src/constants/sports";

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

function validateZip(zip) {
  const re = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return re.test(String(zip).toLowerCase());
}

const ReadyToTrainFormWide = (props) => {
  const [sport, setSport] = useState("");
  const [zip, setZip] = useState("");
  const [searchErrorSnackBar, setSearchErrorSnackBar] = useState(false);
  const [searchErrorSnackBarMessage, setSearchErrorSnackBarMessage] =
    useState("");

  useState(function () {
    GeocodingUtils.GetZip(
      function (res) {
        setZip(res);
      },
      function (err) {
        console.log("err", err);
      }
    );
  });
  const handleForm = (e) => {
    e.preventDefault();

    if (!zip || zip === "" || !validateZip(zip)) {
      setSearchErrorSnackBarMessage("Please enter a valid 5-digit zip code.");
      setSearchErrorSnackBar(true);
      return;
    }

    if (!sport || sport === "") {
      setSearchErrorSnackBarMessage("Please choose a sport.");
      setSearchErrorSnackBar(true);
      return;
    }

    props.history.push({
      pathname: "/coaches",
      search: "?zip=" + zip + "&sport=" + sport + "&radius=50",
      state: { sport: sport, zipCode: zip },
    });
  };

  const takeToBecomeCoach = (e) => {
    e.preventDefault();
    props.history.push({
      pathname: "/join",
      state: { forAthlete: false, sport: sport, zipCode: zip },
    });
  };

  let submitFunc = takeToBecomeCoach;
  if (props.formType === "search") {
    submitFunc = (e) => handleForm(e);
  }

  return (
    <form onSubmit={submitFunc} className="readyToTrainFormWide">
      {/* {props.readyToTrain === "Find Your Private Coach" ? (
        <div className="findYourPrivateCoachHeader">
          <label>{props.readyToTrain}</label>
        </div>
      ) : (
        <label className="readyToTrainText">{props.readyToTrain}</label>
      )} */}

      {/* <TextField
        value={sport}
        select
        onChange={(e) => setSport(e.target.value)}
        name="sport"
        type="sport"
        label="Sport"
        variant="outlined"
        SelectProps={{ style: { boxShadow: "none" } }}
      >
        <MenuItem value={"basketball"}>Basketball</MenuItem>
        <MenuItem value={"soccer"}>Soccer</MenuItem>
        <MenuItem value={"field-hockey"}>Field Hockey</MenuItem>
        <MenuItem value={"lacrosse"}>Lacrosse</MenuItem>
        <MenuItem value={"baseball"}>Baseball</MenuItem>
        <MenuItem value={"softball"}>Softball</MenuItem>
        <MenuItem value={"football"}>Football</MenuItem>
        <MenuItem value={"track-and-field"}>Track &amp; Field</MenuItem>
        <MenuItem value={"strength-and-speed"}>Strength &amp; Speed</MenuItem>
        <MenuItem value={"mental-performance"}>Mental Performance</MenuItem>
      </TextField> */}


      <Autocomplete
        sx={{ width: 200 }}
        selectOnFocus
        autoHighlight={true}
        // autoSelect={true}
        blurOnSelect={true}
        options={sports}
        getOptionLabel={(option) => option.label}
        onChange={(event, newValue) => setSport(newValue ? newValue.value : '')}
        renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <span style={{fontWeight : 100,fontFamily: 'Roboto, Helvetica, Arial, sans-serif'}}>
              {option.label} 
              </span>
            </Box>
          )}
        renderInput={(params) => (
          <TextField
            {...params}
            value={sport}
            name="sport"
            type="sport"
            label="Sport"
            variant="outlined"
          />
        )}
      />

      <TextField
        onChange={(e) => setZip(e.target.value)}
        name="zip"
        value={zip}
        type="zip"
        label="Zip Code"
        variant="outlined"
        inputProps={{ style: { boxShadow: "none" } }}
      />
      <Button className="buttonPrimary" type="submit">
        {props.buttonText}
      </Button>

      <Snackbar
        open={searchErrorSnackBar}
        onClose={(e) => setSearchErrorSnackBar(false)}
      >
        <Alert onClose={(e) => setSearchErrorSnackBar(false)} severity="error">
          {searchErrorSnackBarMessage}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default withRouter(ReadyToTrainFormWide);
