import { CircularProgress } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Close } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import MuiAlert from '@material-ui/lab/Alert';
import React, { useContext, useEffect, useState } from "react";
import ImageUploader from "react-images-upload";
import { createActionImagePresignedUrl, deleteActionImage, getActionImages, getReviews, updateProfile } from '../../api/api';
import Colors from "../../constants/colors";
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import { AuthContext } from "../App/App";
import BottomNav from "../BottomNav/BottomNav";
import CoachProfileBackground from "../CoachProfileBackground/CoachProfileBackground";
import CoachProfileHeader from "../CoachProfileHeader/CoachProfileHeader";
import "./CoachProfile.scss";

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: Colors.background,
  },
}));

const loadActionImages = (id, setActionImages) => {
  getActionImages(id)
    .then(result => {
      console.log('action images', result);
      setActionImages(result['results']);
    })
    .catch(err => {
      console.log('Unable to retrieve action images', err);
    });
};

const CoachProfile = props => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submit = (vals, id) => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        updateProfile(idToken, vals, id)
          .then(response => {
            window.location.reload(false);

            //setOpenSnackBar(true);
          })
          .catch(e => console.log(e));
      });
  };
  const loggedInUser = useContext(AuthContext);

  const user = props.user ? props.user : loggedInUser.user;
  if (!props.user) {
    user.profile.id = loggedInUser.user.firebaseUser.uid;
  }
  const [values, setValues] = useState({});
  const [elevPitch, setElevPitch] = useState(user.profile.elevPitch);
  const [profileField1, setProfileField1] = useState(user.profile.profileField1);
  const [profileField2, setProfileField2] = useState(user.profile.profileField2);
  const [profileField3, setProfileField3] = useState(user.profile.profileField3);
  const [profileField4, setProfileField4] = useState(user.profile.profileField4);
  const [profileField5, setProfileField5] = useState(user.profile.profileField5);
  const [profileField6, setProfileField6] = useState(user.profile.profileField6);
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [fullTime, setFullTime] = useState(user.profile.fullTime);
  const [playingExp, setPlayingExp] = useState(user.profile.playingExp);
  const [viewPublic, setViewPublic] = useState(props.public ? props.public : false);
  const [reviews, setReviews] = useState([]);
  const [avgRating, setAvgRating] = useState(0);
  const [actionImages, setActionImages] = useState([]);
  const [showImageProgress, setShowImageProgress] = useState(false);

  useEffect(() => {
    getReviews(user.profile.id)
      .then(result => {
        setReviews(result['reviews']);
        setAvgRating(result['avgRating']);
      })
      .catch(err => {
        console.log('Unable to retrieve reviews', err);
      });
  }, [user.profile.id]);

  useEffect(() => {
    loadActionImages(user.profile.id, setActionImages);
  }, [user.profile.id]);

  const setStateFunctions = {
    "elevPitch": setElevPitch,
    "profileField1": setProfileField1,
    "profileField2": setProfileField2,
    "profileField3": setProfileField3,
    "profileField4": setProfileField4,
    "profileField5": setProfileField5,
    "profileField6": setProfileField6,
    "playingExp": setPlayingExp,
    "fullTime": setFullTime
  };

  const profileBackgroundStates = {
    "profileField1": profileField1,
    "profileField2": profileField2,
    "profileField3": profileField3,
    "profileField4": profileField4,
    "profileField5": profileField5,
    "profileField6": profileField6,
    "playingExp": playingExp,
    "fullTime": fullTime
  };


  const submitChanges = (e) => {
    e.preventDefault();
    submit(values, user.firebaseUser.uid);
  };

  const onDrop = (picture) => {
    uploadFile(picture[0]);
  };

  const uploadFile = (file) => {
    //console.log('file', file)
    if (!file) {
      return;
    }
    const uploadData = {
      'bucketName': 'athletes-untapped-prod-uploads',
      'uploadedBy': user.firebaseUser.uid,
      'objectKey': file['name']
    };
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        createActionImagePresignedUrl(idToken, uploadData)
          .then(response => {
            console.log('aws presigned info', response);
            sendToAWS(response['url'], file);
            //callback(response['url'])
          })
          .catch(e => console.log(e));
      });
  };

  const sendToAWS = async (url, file) => {
    const response = await fetch(
      new Request(url, {
        method: 'PUT',
        body: file
      }),
    );
    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      console.log(response);
    } else {
      console.log(response);
      //cheat ad reload page
      //hackkkk wait a few seconds for lambda to process image
      setShowImageProgress(true);
      setTimeout(() => {
        setShowImageProgress(false);
        loadActionImages(user.profile.id, setActionImages);
      }, 6000);
    }
  };

  const removeActionImage = id => {
    console.log('here');
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        deleteActionImage(idToken, id)
          .then(response => {
            for (let i = 0; i < actionImages.length; i++) {
              if (actionImages[i]['id'] === id) {
                actionImages.splice(i, 1);
                //make a shallow copy so we trigger the react cycle
                setActionImages([...actionImages]);
                break;
              }
            }
          })
          .catch(e => console.log(e));
      });
  };

  return (
    <div className="coachProfile">

      <div>
        <CoachProfileHeader
          profile={user.profile}
          stateFunctions={setStateFunctions}
          uploadVals={values}
          pitch={elevPitch}
          setUploadVals={setValues}
          submit={submitChanges}
          public={viewPublic}
          setPublic={setViewPublic}
          avgRating={avgRating}
          numRatings={reviews.length}
        />
        <div className={`${classes.root} tabsContainer`}>
          <CoachProfileBackground
            stateFunctions={setStateFunctions}
            uploadVals={values}
            states={profileBackgroundStates}
            setUploadVals={setValues}
            public={viewPublic}
            submit={submitChanges}
          />
          {showImageProgress ?
            <div className="profileProgressContainer"><CircularProgress style={{ color: Colors.secondary }} /></div>
            :
            <div>
              {!viewPublic && actionImages.length <= 12 ?
                <div>
                  <h5>You may upload a maximum of 12 images.<br />Add one photo at a time.</h5>

                  <ImageUploader
                    singleImage={true}
                    withIcon={false}
                    withLabel={true}
                    label="Max file size: 10mb, accepted: jpg, gif, png, jpeg"
                    buttonText='Upload Image'
                    onChange={onDrop}
                    imgExtension={['.jpg', '.gif', '.png', '.jpeg', '.JPG', '.JPEG']}
                    maxFileSize={10485760}
                  />
                </div> : null}
              {!viewPublic && actionImages.length >= 12 ?
                <h5>Maximum upload of 12 images reached.</h5> : null}
            </div>
          }

          {actionImages && actionImages.length > 0 ?
            <div>
              <h3>Photos</h3>
              <div className="actionImages">
                {
                  actionImages.map(actionImage => {
                    return (
                      <div className="imgWrapper">
                        {!viewPublic ? <Close className="closeIcon" onClick={() => removeActionImage(actionImage['id'])} /> : null}
                        <img src={CLOUDFRONT_UPLOADS + actionImage['mediaLocation']} alt="Media" />
                      </div>
                    );
                  })
                }
              </div>
            </div> : null}

          {reviews && reviews.length > 0 ?
            <div className="reviews">
              <h3>Reviews and Testimonials</h3>
              {
                reviews.map((review) => {
                  return (
                    <div className="review">
                      <span className="text-small text-thin">{new Date(review['timestamp'] * 1000).toLocaleString('default', { dateStyle: 'medium' })}</span>
                      <div>
                        <span className="text-bold text-large">{review['authorName']}</span>
                        <br />
                        <Rating className="rating" size="small" value={review['rating']} readOnly />
                      </div>
                      <div className="comments">{review['comments']}</div>
                    </div>
                  );
                })
              }
            </div>
            : null}


        </div>


        <Snackbar open={openSnackbar} onClose={e => setOpenSnackBar(false)}>
          <Alert onClose={e => setOpenSnackBar(false)} severity="success">
            Saved Successfuly!
          </Alert>
        </Snackbar>
      </div>
      <BottomNav></BottomNav>
    </div>
  );
};

export default CoachProfile;