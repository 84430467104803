import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { getProfile } from "../../api/api";
import Actions from "../Actions/Actions";
import { AuthContext } from "../App/App";
import CoachAccount from "../CoachAccount/CoachAccount.js";
import CoachCalendar from "../CoachCalendar/CoachCalendar.js";
import CoachClients from "../CoachClients/CoachClients.js";
import CoachDashboard from "../CoachDashboard/CoachDashboard.js";
import CoachInbox from "../CoachInbox/CoachInbox.js";
import CoachPayments from "../CoachPayments/CoachPayments.js";
import CoachProfile from "../CoachProfile/CoachProfile";
import CoachProfilePage from "../CoachProfilePage/CoachProfilePage";
import CoachVideos from "../CoachVideos/CoachVideos.js";
import CoachWelcome from "../CoachWelcome/CoachWelcome";
import Landing from "../Landing/Landing";
import MobileHeader from "../MobileHeader/MobileHeader";
import SignOut from "../SignOut/SignOut.js";
import "./CoachNav.css";

export default function CoachNav(props) {
  const { user } = useContext(AuthContext);

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    user.firebaseUser.getIdToken().then(function (idToken) {
      getProfile(idToken, user.firebaseUser.uid)
        .then((response) => {
          setProfile(response);
        })
        .catch((e) => console.log(e));
    });
  }, [user.firebaseUser]);

  return (
    <Router>
      <MobileHeader>
        <li>
          <NavLink
            activeClassName="coachNavTabActive"
            to="/dashboard"
            className="coachNavTab"
          >
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/profile"
            activeClassName="coachNavTabActive"
            className="coachNavTab"
          >
            Profile
            {profile && !profile["public_profile_complete"] ? (
              <sup className="redAsterik">*</sup>
            ) : null}
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="coachNavTabActive"
            to="/inbox"
            className="coachNavTab"
          >
            Inbox
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="coachNavTabActive"
            to="/clients"
            className="coachNavTab"
          >
            Bookings
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="coachNavTabActive"
            to="/payments"
            className="coachNavTab"
          >
            Payments
            {profile && !profile["payments_set_up"] ? (
              <sup className="redAsterik">*</sup>
            ) : null}
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="coachNavTabActive"
            to="/calendar"
            className="coachNavTab"
          >
            Calendar
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="coachNavTabActive"
            to="/account"
            className="coachNavTab"
          >
            Account
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="coachNavTabFilledGreenActive"
            to="/videos"
            className="coachNavTabFilledGreen"
          >
            Videos
          </NavLink>
        </li>
        <Link to="/signout" className="coachNavTab">
          Sign Out
        </Link>
      </MobileHeader>
      <div className="coachNavContainerDiv">
        <p className="coachNavGreeting text-large">
          Hi {user.profile.firstName}!
        </p>
        <ul className="coachNavListOfTabs">
          <li>
            <NavLink
              activeClassName="coachNavTabActive"
              to="/dashboard"
              className="coachNavTab"
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/profile"
              activeClassName="coachNavTabActive"
              className="coachNavTab"
            >
              Profile
              {profile && !profile["public_profile_complete"] ? (
                <sup className="redAsterik">*</sup>
              ) : null}
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="coachNavTabActive"
              to="/inbox"
              className="coachNavTab"
            >
              Inbox
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="coachNavTabActive"
              to="/clients"
              className="coachNavTab"
            >
              Bookings
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="coachNavTabActive"
              to="/payments"
              className="coachNavTab"
            >
              Payments
              {profile && !profile["payments_set_up"] ? (
                <sup className="redAsterik">*</sup>
              ) : null}
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="coachNavTabActive"
              to="/calendar"
              className="coachNavTab"
            >
              Calendar
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="coachNavTabActive"
              to="/account"
              className="coachNavTab"
            >
              Account
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="coachNavTabFilledGreenActive"
              to="/videos"
              className="coachNavTabFilledGreen"
            >
              Videos
            </NavLink>
          </li>
        </ul>
        <Link to="/signout" className="coachNavTab">
          Sign Out
        </Link>
      </div>
      <Switch>
        <Route
          path={["/dashboard"]}
          exact
          render={(props) => <CoachDashboard {...props} />}
        />
        <Route
          path="/profile"
          exact
          render={(props) => <CoachProfile {...props} />}
        />
        <Route
          path={["/inbox"]}
          exact
          render={(props) => <CoachInbox {...props} />}
        />
        <Route
          path={["/clients"]}
          exact
          render={(props) => <CoachClients {...props} />}
        />
        <Route
          path={["/calendar"]}
          exact
          render={(props) => <CoachCalendar {...props} />}
        />
        <Route
          path={["/videos"]}
          exact
          render={(props) => <CoachVideos {...props} />}
        />
        <Route
          path={["/payments"]}
          exact
          render={(props) => <CoachPayments {...props} />}
        />
        <Route
          path={["/account"]}
          exact
          render={(props) => <CoachAccount {...props} />}
        />
        <Route
          path="/signout"
          exact
          render={(props) => <SignOut {...props} />}
        />
        <Route
          path="/welcome"
          exact
          render={(props) => <CoachWelcome {...props} />}
        />
        <Route path="/" exact render={(props) => <Landing {...props} />} />
        <Route
          path="/action"
          exact
          render={(props) => <Actions {...props} />}
        />
        <Route
          path="/coach-profile/:id"
          exact
          render={(props) => <CoachProfilePage {...props} />}
        />
        {/* Catch all for paths that don't match one of our routes  */}
        <Redirect to="/dashboard" />
      </Switch>
    </Router>
  );
}
