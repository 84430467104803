import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App/App";
import { createMessage, getAthleteCoaches, getMessages } from '../../api/api';
import RefreshIcon from '@material-ui/icons/Refresh';
import './CoachInbox.scss';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import blank_avatar from "../CoachProfile/avatar.png";
import Button from "@material-ui/core/Button";
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import SearchForCoaches from "../SearchForCoaches/SearchForCoaches";
import { useHistory } from "react-router-dom";
import { getTag } from "@testing-library/jest-dom/dist/utils";

function Alert(props) {
    return <MuiAlert elevation={10} variant="filled" {...props} />;
}


const CoachInbox = (props) => {
    const { user } = useContext(AuthContext);
    const history = useHistory();
    const [messages, setMessages] = useState({});
    const [, setRelatedUsers] = useState([]);
    const [conversations, setConversations] = useState({});
    const [orderedConversationKeys, setOrderedConversationKeys] = useState([]);
    const [currentConv, setCurrentConv] = useState("");
    const [messageToSend, setMessageToSend] = useState("");
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [error, setError] = useState(null);
    const [messagesEnd, setMessagesEnd] = useState(null);
    const [sendDisabled, setSendDisabled] = useState(false);
    const [quickMessage, setQuickMessage] = useState(null);

    useEffect(() => {
        if (messagesEnd !== null) {
            messagesEnd.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentConv, messages, messagesEnd]);

    useEffect(() => {
        user.firebaseUser.getIdToken()
            .then(function (idToken) {
                getMessages(idToken, user.firebaseUser.uid)
                    .then(response => {
                        setMessages(response.messages);
                        console.log('conversations response', response);
                        setConversations(response.conversations);
                        const sorted = Object.keys(response.conversations).sort((a, b) => {
                            const aTime = response.messages[a][response.messages[a].length - 1]['time'];
                            const bTime = response.messages[b][response.messages[b].length - 1]['time'];
                            console.log('a and b', aTime, bTime);
                            if (aTime < bTime) {
                                return 1;
                            } else if (bTime < aTime) {
                                return -1;
                            }
                            return 0;
                        })
                        setOrderedConversationKeys(sorted);
                        setCurrentConv(sorted[0]);
                    })
                    .catch(error => console.log(error));
            });
    }, [user.firebaseUser]);

    useEffect(() => {
        user.firebaseUser.getIdToken()
            .then(function (idToken) {
                if (user.profile.profileType !== 'coach') {
                    getAthleteCoaches(idToken, user.firebaseUser.uid)
                        .then(response => {
                            setRelatedUsers(user.profile.profileType !== 'coach' ? response['coaches'] : response['clients']);
                        })
                        .catch(error => console.log(error));
                } else {

                }
            });
    }, [user.firebaseUser, user.profile.profileType]);

    const getAllMessages = (id) => {
        user.firebaseUser.getIdToken()
            .then(function (idToken) {
                getMessages(idToken, id)
                    .then(response => {
                        setMessages(response.messages);
                        setConversations(response.conversations);

                    })
                    .catch(error => console.log(error));
            });
    };


    const sendMessage = (e, message) => {
        e.preventDefault();
        // const phoneRegex = /(\+\d{1,2}\s?)?((\(\d{3}\))|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}/g;
        // const phoneRegex = /(\+\d{1,2}\s?)?((\(\d{3}\))|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}|\d{3}-\d{3}-\d{4}/g;
        // const phoneRegex = /(?:\+?\d{1,2}\s*)?(?:\d[\s.-]*){10}/g;
        const phoneRegex = /(?:\+?\d{1,2}\s*)?(\(?\d{3}\)?[\s.-]*){2}\d{4}/g;
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
        // test returns 'true' if there's a match and 'false' if there is not
        const phoneMatch = message.match(phoneRegex);
        const emailMatch = message.match(emailRegex);
        if((phoneMatch && phoneMatch.length > 0) || (emailMatch && emailMatch.length > 0)) {
            setError("Friendly reminder - for everyone’s privacy, all communication and bookings take place through our website or app. You're welcome to share personal contact information once you meet in-person!");
            return;
        }
        setSendDisabled(true);

        getTag('event', 'message_sent');
        getTag('event', 'conversion');
        
        const messageToSend = {
            "to": currentConv,
            "from": user.firebaseUser.uid,
            "content": message,
            "time": Date.now() // number of milliseconds since Jan 1 1970 UTC
        };
        user.firebaseUser.getIdToken()
            .then(function (idToken) {
                createMessage(idToken, messageToSend)
                    .then(response => {
                        setOpenSnackBar(true);
                        setMessageToSend("");
                        getAllMessages(user.firebaseUser.uid);
                        setSendDisabled(false);
                    })
                    .catch(e => {
                        console.log(e);
                        setSendDisabled(false);
                    });
            });
    };

    const refreshHandler = (e) => {
        e.preventDefault();
        getAllMessages(user.firebaseUser.uid);
    };

    const getDisplayName = (conversation, msg) => {
        if (msg['from'] !== user.firebaseUser.uid) {
            return getCorrectedName(conversation);
        } else {
            return getCorrectedName(user.profile);
        }
    };

    const getCorrectedName = (profile) => {
        if (profile.parentFirstName) {
            return profile['parentFirstName'] + " " + profile['parentLastName'];
        } else {
            return profile['firstName'] + " " + profile['lastName'];
        }
    };

    const copyProfileLinkToClipboard = () => {
        console.log('profile', user);
        let url = window.location.protocol + '//' + window.location.hostname + '/book-session/' + (user.profile.customUrl ? user.profile.customUrl : user.firebaseUser.uid);
        navigator.clipboard.writeText(url);
        setQuickMessage("Booking link copied to clipboard! Paste & send to your client.");
    }

    return (
        <div className="inbox">


            {Object.keys(messages).length === 0 ?
                user.profile.profileType !== 'coach' ?
                    <div className="lookingForNew">
                        <p>Once you message an AU coach, your correspondence will appear here!</p>
                        <SearchForCoaches />
                    </div>
                    :
                    <div className="lookingForNew">
                        <p>Once clients initially reach out to you, you will be able to message them here!</p>
                    </div>
                :
                <div>
                    <div className="CoachInboxHeader">
                        <p>Messages</p>
                        {(currentConv !== "") ?
                            <p>{conversations[currentConv]['firstName']} {conversations[currentConv]['lastName']}</p> : null}
                        <div>
                            <RefreshIcon className="CoachInboxIconButtons" onClick={e => refreshHandler(e)} />
                        </div>
                    </div>
                    <div className="CoachInboxBody">
                        <div className="CoachInboxConversations">
                            {orderedConversationKeys.map((item, i) => (
                                <div
                                    className={`messageListItem ${conversations[currentConv] === conversations[item] ? 'selected' : ''}`}
                                    onClick={e => {
                                        setCurrentConv(item);
                                    }}>
                                    <div className="items">
                                        <div className="left">
                                            <span className="name">{getCorrectedName(conversations[item])}</span>
                                            <div className="message">
                                                {messages[item][messages[item].length - 1]['content']}
                                            </div>
                                        </div>
                                        <div
                                            className="date">{new Date(messages[item][messages[item].length - 1]['time']).toLocaleString('default', { dateStyle: 'medium' })}</div>
                                        <div className="rightCover"></div>
                                    </div>

                                </div>
                            ))}
                        </div>
                        <div className="CoachInboxMessageWindow">
                            <div className="CoachInboxMessageWindowFirstChild">
                                <div className="buttonBox">
                                    {user.profile.profileType === 'coach' ?
                                        <Button className="buttonPrimary" onClick={() => copyProfileLinkToClipboard()}>Send Your Booking Link!</Button>
                                        :
                                        <Button className="buttonPrimary" onClick={() => history.push('/book-session/' + (conversations[currentConv].customUrl ? conversations[currentConv].customUrl : currentConv))}>Book More Sessions!</Button>
                                    }
                                </div>
                                <div className="warningBoxContainer">
                                    <div className="warningBox">To protect your payment, always book and pay through the
                                        AU platform.
                                    </div>
                                </div>
                                {(currentConv !== "") ? (messages[currentConv].map((msg, i) =>
                                (
                                    <Paper
                                        square
                                        key={i}
                                        elevation={0}
                                        className="CoachInboxMessageContent">

                                        {(i === 0 || (new Date(msg['time']).getUTCDay() !== new Date(messages[currentConv][i - 1]['time']).getUTCDay()))
                                            ? <p className="CoachInboxDateSeparatorText">
                                                {new Date(msg['time']).toDateString()}
                                            </p>
                                            : null}
                                        <div className="messageInner">
                                            {(msg['from'] !== user.firebaseUser.uid && conversations[currentConv]['profileImage']) || (msg['from'] === user.firebaseUser.uid && user.profile['profileImage']) ?
                                                <img
                                                    src={CLOUDFRONT_UPLOADS + (msg['from'] !== user.firebaseUser.uid ? conversations[currentConv]['profileImage'] : user.profile['profileImage'])}
                                                    alt="Profile" className="userAvatar" />
                                                :
                                                <img src={blank_avatar} alt="Blank Avatar" className="userAvatar" />
                                            }
                                            <div className="messageRight">
                                                <div className="CoachInboxMessageContentNameandDate">
                                                    <p className="CoachInboxMessageContentName">
                                                        {getDisplayName(conversations[currentConv], msg)}
                                                    </p>
                                                    <p className="CoachInboxMessageContentDate">
                                                        {new Date(msg['time']).toLocaleTimeString([], {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        })}
                                                    </p>
                                                </div>
                                                <div className="text-thin theMessage">{msg['content']}</div>
                                            </div>
                                        </div>

                                    </Paper>
                                ))) : null}
                                <div style={{ float: "left", clear: "both" }}
                                    ref={(el) => {
                                        setMessagesEnd(el);
                                    }}></div>
                            </div>
                            <div className="CoachInboxMessageWindowSecondChild">
                                {currentConv !== "" ?
                                    <div className="CoachInboxMessageToSend">
                                        <textarea
                                            placeholder="Type a message..."
                                            value={messageToSend}
                                            onChange={e => setMessageToSend(e.target.value)}>
                                        </textarea>
                                        <Button
                                            disabled={sendDisabled}
                                            className="buttonPrimary sendButton"
                                            onClick={e => sendMessage(e, messageToSend)}>
                                            Send
                                        </Button>
                                        <Snackbar open={openSnackbar}
                                            onClose={e => setOpenSnackBar(false)}>
                                            <Alert onClose={e => setOpenSnackBar(false)} severity="success">
                                                Message Sent!
                                            </Alert>
                                        </Snackbar>
                                        <Snackbar  open={error}
                                            onClose={e => setError(null)}>
                                            <Alert onClose={e => setError(null)} severity="success">
                                                {error}
                                            </Alert>
                                        </Snackbar>
                                    </div> : null}
                            </div>

                        </div>
                    </div>

                </div>}
            <Snackbar open={quickMessage} autoHideDuration="5000"
                onClose={e => setQuickMessage(null)}>
                <Alert onClose={e => setQuickMessage(null)} severity="success">
                    {quickMessage}
                </Alert>
            </Snackbar>
        </div >
    );
}
    ;

export default CoachInbox;