import React, {useState} from "react";
import {Menu} from "@material-ui/icons";
import AULogo from '../HomeNav/AULogo.png';
import './MobileHeaderr.scss';
import {useHistory} from "react-router-dom";

const MobileHeader = props => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const children = React.Children.map(
    props.children,
    function (child) {
      return <li onClick={() => setOpen(false)}>{child}</li>;
    }
  );
  return (
    <div className="mobileHeader">
      <img alt="" src={AULogo} className="logo" onClick={() => history.push('/')}/>
      <Menu className="menuIcon" onClick={() => setOpen(!open)}/>
      <ul className={`menu ${open ? 'open' : ''}`}>{children}</ul>
    </div>
  );
};

export default MobileHeader;