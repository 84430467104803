import React from "react";

import { Box, Input, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Link } from "react-router-dom";
import logosmall from "../HomeNav/AULogoSmall.png";

export default function BookingBoxScroller({
  additionalAthletesRate,
  numAthletes,
  numSessions,
  sessionRate,
  setNumAthletes,
  setNumSessions,
}) {
  return (
    <div className="bookBoxScroller">
      <div className="bookBox">
        <h4>Book Now</h4>
        <div className="left-align">
          ${sessionRate}/Session
          <br />
          <br />
          <Input
            type="text"
            value={numSessions}
            onChange={(e) => {
              const strippedInput = e.target.value.replace(/[^\d]/g, "");
              const value = strippedInput === "" ? 0 : parseInt(strippedInput);
              if (value > 100) {
                return;
              }

              setNumSessions(value);
            }}
          />
          <span>Sessions</span>
          {additionalAthletesRate ? (
            <Box mt={1}>
              <Input
                type="text"
                value={numAthletes}
                onChange={(e) => {
                  const strippedInput = e.target.value.replace(/[^\d]/g, "");
                  const value =
                    strippedInput === "" ? 0 : parseInt(strippedInput);
                  if (value > 100) {
                    return;
                  }

                  setNumAthletes(value);
                }}
              />
              <span>Athletes</span>
              <Tooltip title={`$${additionalAthletesRate}/additional athlete`}>
                <Info
                  className="toolTipIcon"
                  style={{ height: 12, width: 12, color: "#999" }}
                />
              </Tooltip>
            </Box>
          ) : undefined}
        </div>
        <div className="total">
          <div>Total</div>
          <div>${(sessionRate * numSessions).toFixed(2)}</div>
        </div>
        <span className="text-thin">
          You are welcome to book anywhere from 1-100 sessions, but most parents
          book 5 sessions upfront to lock-in the dates on their coaches
          calendar. Our satisfaction guarantee still applies!
        </span>
      </div>
      <img alt="" src={logosmall} />
      <h6>Satisfaction guaranteed, or your money back.</h6>
      <div>
        If you do not believe this coach was the right fit after your first
        session, you can receive a full refund. Read our{" "}
        <Link to="/site-terms" className="link">
          refund policy
        </Link>
        . .
      </div>
    </div>
  );
}
