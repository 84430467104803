import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../App/App";
import {awsPresignedPost, getCompletedEvents} from '../../api/api';
import './CoachVideos.scss';
import CoachVideosClientPanel from '../CoachVideosClientPanel/CoachVideosClientPanel';
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import CoachVideosClientView from '../CoachVideosClientView/CoachVideosClientView';
import {useParams} from "react-router";
import logo from '../HomeNav/AULogoSmall.png';


const CoachVideos = props => {

  const {user} = useContext(AuthContext);
  const {clientId} = useParams();
  const [uploadData, setUploadData] = useState({
    'uploadedBy': user.firebaseUser.uid,
    'uploadDate': null,
    'bucketName': 'athletes-untapped-prod-uploads',

  });
  const [fileToUpload, setFileToUpload] = useState(null);
  const [, setSessions] = useState([]);
  const [sessionsByClient, setSessionsByClient] = useState({});
  const [clients, setClients] = useState({});
  const [videoUploadSelectedClient, setVideoUploadSelectedClient] = useState(null);
  const [videoUploadSelectedSession, setVideoUploadSelectedSession] = useState(null);
  const [showAddVideoDialog, setShowAddVideoDialog] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getCompletedEvents(idToken, user.firebaseUser.uid, 'coach')
          .then(response => {
            setSessions(response['completedEvents']);
            setSessionsByClient(response['completedEventsByClient']);
            setClients(response['clients']);
            if (Object.keys(response['clients']).length > 0) {
              setVideoUploadSelectedClient(Object.keys(response['clients'])[0]);
            }
            console.log(response);
            console.log(Object.keys(response['clients']));
          })
          .catch(e => console.log(e));
      });
  }, [user.firebaseUser, clientId]);

  const onAddVideoDialogClose = () => {
    setShowAddVideoDialog(false);
  };

  const onUploadChange = e => {
    console.log(e.target.files[0]);
    setUploadData({
      ...uploadData,
      'uploadDate': e.target.files[0] ? e.target.files[0]['lastModified'] : null,
      'objectKey': e.target.files[0] ? ('videos/' + e.target.files[0]['name']) : null,
    });
    setFileToUpload(e.target.files[0]);

  };

  const uploadFile = (e) => {
    e.preventDefault();

    if (!uploadData['uploadDate'] || !uploadData['objectKey']) {
      console.log('Pick a file to upload.');
      return;
    }
    setUploadData({
      ...uploadData,

    });
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        awsPresignedPost(idToken, uploadData)
          .then(response => {
            console.log(response);
            sendToAWS(response['url']);
            //callback(response['url'])
          })
          .catch(e => console.log(e));
      });
  };

  const sendToAWS = async url => {
    const response = await fetch(
      new Request(url, {
        method: 'PUT',
        body: fileToUpload,
        headers: new Headers({
          'Content-Type': 'video/quicktime',
        }),
      }),
    );
    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      console.log(response);
    } else {
      console.log(response);
    }
    /*awsUploadFile(url, fileToUpload)
    .then(response => {
        console.log(response);
    })
    .catch(e => console.log(e));*/
  };

  const addVideo = (clientId, sessionId) => {
    setVideoUploadSelectedClient(clientId);
    setVideoUploadSelectedSession(sessionId);
    setShowAddVideoDialog(true);
  };


  return (
    <div className="videos">
      <div>
        <div className="videoPageIntro">
          <img alt="logo" src={logo}/>
          <div className="centerContent">
            <h5>Finally, a coaching platform that offers video feedback!</h5>
            <p>Using video to highlight areas of improvement is one of the most powerful ways to help a young athlete improve their game. Show your expertise as a coach by breaking down your client’s game and relaying specific points of emphasis so they know how to improve between now and your
              next training session.</p>
            <h5>Here's how it works:</h5>
            <ol>
              <li>Take a video, up to 60 seconds in length, on your phone OR ask a parent to help by taking the video.</li>
              <li>Upload the video below by clicking <b>Add Video</b> next to a session.</li>
              <li>Leave comments on the video so your client can see themselves in action, understand the weak (or strong) parts of their game, and prepare to make adjustments for your next training session.</li>
            </ol>
          </div>
        </div>

      </div>
      <div className="sessionList">
        {Object.keys(sessionsByClient).map((clientID, index) => {
          if (clients[clientID]) {
            return (
              <CoachVideosClientView
                for="coach"
                client={clients[clientID]}
                clientID={clientID}
                clientSessions={sessionsByClient[clientID]}
                addVideo={addVideo}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      <Dialog onClose={onAddVideoDialogClose} aria-labelledby="customized-dialog-title" open={showAddVideoDialog} maxWidth="md" fullWidth={true}>
        <DialogTitle id="customized-dialog-title" onClose={onAddVideoDialogClose}>
          Upload Video
        </DialogTitle>
        <DialogContent dividers>
          <CoachVideosClientPanel
            for="coach"
            onPickFile={onUploadChange}
            onSubmit={uploadFile}
            client={clients[videoUploadSelectedClient]}
            clientID={videoUploadSelectedClient}
            clientSessions={sessionsByClient[videoUploadSelectedClient]}
            selectedSession={videoUploadSelectedSession}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CoachVideos;