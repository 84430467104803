import { Box, Input, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { Info } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { createMessage } from "../../api/api";
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import { calculateSessionRateWithAdditionalAthletes } from "../../utils/shared";
import { AuthContext } from "../App/App";
import LoginSignupDialog from "../LoginSignupDialog/LoginSignupDialog";
import "./AthleteMessageCoach.scss";
import blank_avatar from "./avatar.png";

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

const AthleteMessageCoach = (props) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [messageContent, setMessageContent] = useState("");
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [numSessions, setNumSessions] = useState(5);
  const [numAthletes, setNumAthletes] = useState(1);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [
    sessionRateWithAdditionalAthletes,
    setSessionRateWithAdditionalAthletes,
  ] = useState(props.coach.sessionRate);

  const sendMessage = useCallback((message) => {
    const messageToSend = {
      to: props.coach.firebaseID,
      from: user.firebaseUser.uid,
      content: message,
      time: Date.now(), // number of milliseconds since Jan 1 1970 UTC
    };
    user.firebaseUser.getIdToken().then(function (idToken) {
      createMessage(idToken, messageToSend)
        .then((response) => {
          setOpenSnackBar(true);
          setMessageContent("");
        })
        .catch((e) => console.log(e));
    });
  }, [props, user]);
  const book = () => {
    history.push({
      pathname: "/book-session/" + props.coach.id,
      state: { numSessions, numAthletes, sessionRateWithAdditionalAthletes },
    });
  };

  const login = () => {
    setShowLogin(true);
    setShowSignup(false);
  };

  const signup = () => {
    setShowLogin(true);
    setShowSignup(true);
  };

  useEffect(() => {
    const effectiveRate = calculateSessionRateWithAdditionalAthletes({
      sessionRateString: props.coach.sessionRate,
      additionalAthletesRateString: props.coach.additionalAthletesRate,
      numAthletes,
    });

    setSessionRateWithAdditionalAthletes(effectiveRate);
  }, [props.coach, numAthletes, numSessions]);

  useEffect(() => {
    if (user) {
      const savedMessageContent = localStorage.getItem("messageContent");
      if (savedMessageContent) {
        setMessageContent(savedMessageContent);
        if (!containsSensitiveInfo(savedMessageContent)) {
          sendMessage(savedMessageContent);
        }
        localStorage.removeItem("messageContent");
        history.push("/dashboard");
      }
    }
  }, [user, sendMessage, history]);

  function containsSensitiveInfo(message) {
    // Detects 10-digit numeric values
    const phoneRegex = /\b\d{10}\b/;

    // Detects email-like expressions
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;

    return phoneRegex.test(message) || emailRegex.test(message);
  }

  return (
    <div className="messageCoachContainer">
      <div className="left">
        <h2>Contact {props.coach.firstName}</h2>
        <div className="coachInfo">
          {props.coach && props.coach["profileImage"] ? (
            <div
              className="coachProfileHeaderAvatar"
              style={{
                backgroundImage:
                  "url('" +
                  CLOUDFRONT_UPLOADS +
                  props.coach["profileImage"] +
                  "')",
              }}
            ></div>
          ) : (
            <div
              className="coachProfileHeaderAvatar"
              style={{ backgroundImage: "url('" + blank_avatar + "')" }}
            ></div>
          )}
          <div>
            <span className="text-bold">
              {props.coach.firstName} {props.coach.lastName.substr(0, 1)}
            </span>
            <br />
            {props.coach.locationInfo.city}, {props.coach.locationInfo.state}
            <br />
            <span className="uc-words">
              Private {props.coach.sport.join(", ")} Coach
            </span>
          </div>
        </div>
        <div className="questions">
          <h4>Most parents/athletes ask about</h4>

          <em>Availability</em>
          <div className="bulletedQuestion">
            <div className="bullet" />
            <div className="text">
              Ex: Are you available to train on the weekends? Or how about
              weeknights after 5pm?
            </div>
          </div>
          <em>Location</em>
          <div className="bulletedQuestion">
            <div className="bullet" />
            <div className="text">
              Ex: Do you have a place where you typically train? Or can we find
              somewhere closer to us?
            </div>
          </div>
          <em>Goals</em>
          <div className="bulletedQuestion">
            <div className="bullet" />
            <div className="text">
              Ex: My son needs to improve his quickness and footwork in order to
              make the team next year. Is this something you can help with?
            </div>
          </div>
        </div>
        <div className="message">
          <h4>Message {props.coach.firstName}</h4>
          {user == null ? (
            // <div>
            //   <textarea
            //     value={messageContent}
            //     onChange={(e) => setMessageContent(e.target.value)}
            //   ></textarea>
            //   <Button
            //     className="buttonPrimary"
            //     onClick={(e) => sendMessage(props.coach)}
            //   >
            //     Send Message
            //   </Button>
            // </div>

            // encryted messaging functionality [ prevents email and phone nos. sending ]
            <div className="login-message">
              <textarea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
              ></textarea>
              <Button
                className="buttonPrimary"
                onClick={(e) => {
                  setShowLogin(true);
                  setShowSignup(true);
                  localStorage.setItem("messageContent", messageContent);
                }}
              >
                Send Message
              </Button>
            </div>
          ) : (
            <div>
              <textarea
                value={messageContent}
                onChange={(e) => {
                  setMessageContent(e.target.value)
                }}
              ></textarea>
              <Button
                className="buttonPrimary"
                onClick={(e) => {
                  if (containsSensitiveInfo(messageContent)) {
                    alert(
                      "All communication is required to stay on the platform - it ensures privacy & payment security for the AU community."
                    );
                  } else {
                    console.log("message sent to coach !");
                    sendMessage(messageContent);
                  }
                }}
              >
                Send Message
              </Button>
            </div>
          )}
          <Snackbar open={openSnackbar} onClose={(e) => setOpenSnackBar(false)}>
            <Alert onClose={(e) => setOpenSnackBar(false)} severity="success">
              Message Sent!
            </Alert>
          </Snackbar>
        </div>
      </div>
      <div className="right">
        {props.setShowProfile ? (
          <span
            className="link"
            onClick={(e) => {
              props.setDisplayMessage(false);
              props.setShowProfile(true);
            }}
          >
            Go back to coach profile
          </span>
        ) : null}
        <div className="bookBox">
          <h4>Book Now</h4>
          <div className="left-align">
            ${sessionRateWithAdditionalAthletes}/Session
            <br />
            <br />
            <Input
              type="text"
              value={numSessions}
              onChange={(e) => setNumSessions(e.target.value)}
            />
            <span>Sessions</span>
            {props.coach.additionalAthletesRate ? (
              <Box mt={1}>
                <Input
                  type="text"
                  value={numAthletes}
                  onChange={(e) => {
                    if (e.target.value > 100) {
                      return;
                    }

                    setNumAthletes(e.target.value);
                  }}
                />
                <span>Athletes { }</span>
                <Tooltip
                  title={`$${props.coach.additionalAthletesRate}/additional athlete`}
                >
                  <Info
                    className="toolTipIcon"
                    style={{ height: 12, width: 12, color: "#999" }}
                  />
                </Tooltip>
              </Box>
            ) : undefined}
          </div>
          <div className="total">
            <div>Total</div>
            <div>${sessionRateWithAdditionalAthletes * numSessions}</div>
          </div>
          {user != null ? (
            <div>
              <Button className="buttonPrimary" onClick={book}>
                Continue
              </Button>
              <div className="notCharged">You will not be charged yet.</div>
            </div>
          ) : (
            <div>
              <Button className="buttonPrimary" onClick={login}>
                Log In to Book
              </Button>
              <span className="link text-small signup-link" onClick={signup}>
                Create an Account (Free)
              </span>
            </div>
          )}
        </div>
        Need help finding the right coach?
        <br />
        <br />
        Get in Touch:{" "}
        <a className="link" href="mailto:community@athletesuntapped.com">
          community@athletesuntapped.com
        </a>
      </div>
      <LoginSignupDialog
        show={showLogin}
        close={() => setShowLogin(false)}
        showLogin={!showSignup}
        returnAfterLogin={true}
      />
    </div>
  );
};
export default AthleteMessageCoach;